<template>
  <div class="new_register_page">
    <!-- Fullscreen Loader -->
    <FullscreenLoader v-if="loader" />
  
    <!-- CONTENTS -->
    <div class="register-page-container">
      
      <div class="register-page-form">

        <!-- HEADER -->
        <div class="mob_header_cont" >

          <!-- HEADER DSEKTOP -->
          <div class="mob_header hide_on_mobile">
            <div class="mob_header_div mob_div_pl display_flex_left">
              <img src="/svg/icon_white_arrow_left.svg" role="button" @click="areYouSure = true"><br>
            </div>
            <div class="mob_header_div mob_div_pr display_flex_right">
              <img src="/apalywhite.svg" class="mob_header_logo"/>
            </div>
          </div>

          <!-- HEADER MOBILE -->
          <div class="mob_header hide_on_desktop">
            <div class="mob_header_div display_flex_left">
              <img src="/svg/icon_white_arrow_left.svg" role="button" @click="areYouSure = true"><br>
            </div>
            <div class="mob_header_div display_flex_right">
              <img src="/apalywhite.svg" class="mob_header_logo"/>
            </div>
          </div>

          <!-- STEPS NO SELECTED PROVIDER-->
          <div class="mob_step_cont" v-if="!selectedProvider">
            <div class="mob_step">
              <div class="mob_step_num mob_step_done">1</div>
              <div class="mob_step_text">Personalize <br> your search</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num mob_step_done">2</div>
              <div class="mob_step_text">Select <br> Provider</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num mob_step_active">3</div>
              <div class="mob_step_text">Verify your <br> eligibility</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num">4</div>
              <div class="mob_step_text">Schedule your<br> first visit</div>
            </div>
          </div>

          <!-- STEPS WITH SELECTED PROVIDER-->
          <div class="mob_step_cont" v-else>
            <div class="mob_step">
              <div class="mob_step_num mob_step_done">1</div>
              <div class="mob_step_text">Select <br> Provider</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num mob_step_done">2</div>
              <div class="mob_step_text">Create <br> account</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num mob_step_active">3</div>
              <div class="mob_step_text">Verify your <br> eligibility</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num">4</div>
              <div class="mob_step_text">Schedule your<br> first visit</div>
            </div>
          </div>

        </div>

        <!-- DPC/PROVIDER DETAILS -->
        <div class="mob_header_bg mob_adjust_margin" v-if="selectedProvider">
          <div class="mob_header_provider_cont">
            <div class="mob_header_title" >You've selected:</div>
            <div class="provider_container">
              <!-- LOGOS -->
              <div class="provider_logo_container">
                <div class="provider_logo" v-if="selectedProvider && selectedProvider.logo" :style="`background-image:url(${selectedProvider.logo})`"></div>
                <div class="provider_logo" v-else :style="`background-image:url('/tpa/clinicsAndOrganizations.svg')`"></div>
              </div>

              <span class="provider_location">
                <span class="font-16 provider_title">{{ selectedProvider.companyName }}</span> 
                <br /> 
                <span class="font-12 provider_location">
                  {{ selectedProvider.contact?.address1 }},
                  {{ selectedProvider.contact?.city }},
                  {{ selectedProvider.contact?.state }},
                  {{ selectedProvider.contact?.zipCode }}
                </span>
              </span>
            </div>
          </div>
        </div>

        <!-- FORM -->
        <!-- <div  class="form_desk_up"> -->
        <div :class="`form_desk_up mob_register_form ${!selectedProvider ? 'mob_adjust_margin':''}`">
          <v-form v-model.trim="valid" ref="form" class="authentication-page-right-side-form form_margin_bottom" v-if="!selectProviderNow">

            <!-- CONGRATULATIONS -->
            <!-- <div class="display_flex display_flex_mid login_badge"  style="border:1px solid #CCCCCC; border-radius: 8px;">
                <img src="/svg/checkSvg.svg" class="mr-3">
                <div class="d-block">
                  <span class="font-20">Congratulations!</span><br>
                  <span>Your employer pays for this benefit! </span> 
                </div>
            </div> -->


              <!-- <p class="mb-0" style="font-size: 36px; font-weight: 600">Last Step </p> -->
              <p v-if="!toRevise" class="mb-md-7 mb-3 text-gray mt-5" style="font-size: 22px; font-weight: bold; color:#102a4d">
                <!-- Now, let's add a family member. -->
                Great, now let's confirm your eligibility.
              </p>

              <div v-else class="form_warn">
                <!-- <span>Oops,</span> <br> -->
                <!-- We were unable to verify eligibility. Please verify your information and resubmit.
                 -->
                We were unable to verify your eligibility. Please review your information and try again.
              </div>

              <!-- <div class="font-20 mb-2 bold_label">Personal Information</div> -->
              <div class="font-20 mb-2 bold_label">Personal Information</div>
              <div>
                <label><b>First Name</b> <small>(as shown on your insurance card)</small></label>
                <v-text-field
                  placeholder="Enter First Name"
                  outlined
                  dense
                  v-model.trim="member.firstName"
                  :rules="requiredRules"
                  style="border-radius: 8px;"
                ></v-text-field>
              </div>
              <div>
                <label><b>Last Name</b> <small>(as shown on your insurance card)</small></label>
                <v-text-field
                  placeholder="Enter Last Name"
                  outlined
                  dense
                  v-model.trim="member.lastName"
                  :rules="requiredRules"
                  style="border-radius: 8px;"
                ></v-text-field>
              </div>
              <div>
                <div>
                  <label><b>Date of Birth</b></label>
                  <v-menu
                  ref="menuDob"
                  hide-details
                  v-model.trim="calendarMenu1"
                  transition="scale-transition"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                  location="start"
                  :nudge-bottom="10"
                >
                  <template v-slot:activator="{ on, attrs }">
                    
                    <v-text-field
                      v-model="dateFormatted"
                      @input="parseDate(dateFormatted, 'member')"
                      outlined
                      v-mask="'##/##/####'"
                      style="border-radius: 8px;"
                      :rules="requiredRules"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      v-bind="attrs"
                      placeholder="MM/DD/YYYY"
                      v-on="on"
                      :error-messages="memberDobError"
                    ></v-text-field>
                  </template>
                </v-menu>
              </div>
                <div>
                  <label><b>Gender</b></label>
                  <v-select v-model="member.gender" :items="genders" placeholder="Select" item-text="text" item-value="value" :rules="requiredRules" outlined dense></v-select>
                </div>
                <div>
                  <label><b>Mobile Phone Number</b></label>
                  <v-text-field 
                  v-model="formattedPhoneNumber"
                  style="border-radius: 8px;"
                  maxlength="12"
                  @input="formatPhoneNumber"
                  placeholder="Enter Mobile Phone Number: (999) 999-9999"
                  :rules="phoneRules" outlined dense></v-text-field>
                </div>
              
              </div>
              <div class="font-20 bold_label mt-5">Insurance Information</div>
              <p class="font-12">As shown on your insurance card</p>
              <div>
              <label class="label-color"><b>Employer</b></label>

              <v-select
                v-model="selectedEmployerInfo"
                :items="employers"
                placeholder="Select which company you're working for"
                @change="selectedEmployer"
                item-value="id"
                item-text="companyName"
                return-object
                :rules="requiredRules"
                disabled
                class="custom-v-select"
                outlined dense
                no-data-text="No employer found"
              >
                <template v-slot:prepend-item>
                  <div class="custom_select_input_container">
                    <input type="text" class="custom_select_input" v-model="searchTerm" placeholder="Search employer here" @input="searchingEmployers"/>
                  </div>
                </template>
                <!-- Show Selected Employer -->
                <template #selection="{ item }">
                  {{ item.companyName }}
                </template>

                <!-- List of Employers -->
                <template #item="{ item }">
                  <div>{{ item.companyName }}</div>
               </template>

                <template #item-disabled="{ item }">
                  <div >{{ item.companyName }}</div>
                </template>
              </v-select>
              
            </div>
            <div>
              <div style="display:flex;gap:0px;align-items:center;">
                <label><b>What is your relationship to the subscriber?</b></label>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      class="pl-2"
                      src="/icon_blue_tooltip.svg"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span style="border-radius: 8px">
                    Person whose employment makes them eligible for <br> group health insurance benefits.
                  </span
                  >
                </v-tooltip>
              </div>
                <v-select
                  v-model="member.subscriberRelation"
                  :items="relationships"
                  item-text="text"
                  item-value="value"
                  :rules="requiredRules"
                  placeholder="Select"
                  outlined
                  dense
                  style="border-radius: 8px;"
                  @change="changeSubscriberRelation()"
                ></v-select>
              </div>
              <div>
                <label><b>What is your member ID?</b></label>
                <v-text-field
                  placeholder="Enter Member ID from your Insurance Card"
                  outlined
                  dense
                  v-model.trim="member.memberId"
                  style="border-radius: 8px;"
                  :rules="requiredRules"
                  @input="validateMemberId(member.memberId, 'memberId')"
                  :error-messages="errorMessages.memberId"
                ></v-text-field>
              </div>
              <div v-if="showPayerField">
                <label>What is your payer ID?</label>
                <v-select
                  v-model="member.payerId"
                  :items="member.payers"
                  item-text="payerId"
                  item-value="payerId"
                  :rules="requiredRules"
                  placeholder="Select"
                  outlined
                  dense
                  style="border-radius: 8px;"
                >
                  <template v-slot:item="{ item }">
                    {{ item.payerId }} - {{ item.payerName }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item ? `${item.payerId} - ${item.payerName}` : '' }}
                  </template>
                </v-select>
              </div>
              <!-- <div v-else>
                <label>What is your payer ID?</label>
                <v-text-field
                  placeholder="Payer Id"
                  outlined
                  dense
                  v-model.trim="member.payerId"
                  :rules="requiredRules"
                  style="border-radius: 8px;"
                ></v-text-field>
              </div> -->
              <div>
                <label><b>Health Plan</b></label>
                <v-select
                  v-model="member.planId"
                  :items="plansList"
                  item-text="name"
                  item-value="planId"
                  :rules="requiredRules"
                  style="border-radius: 8px;"
                  :disabled="showPayerField && !member.payerId"
                  placeholder="Select"
                  outlined
                  dense
                ></v-select>
              </div>
              <div v-if="member.subscriberRelation == 'Spouse' || member.subscriberRelation == 'Dependent'">
                <label><b>What is the employee's member ID?</b></label>
                <v-text-field
                  placeholder="Enter Member ID from your Insurance Card"
                  outlined
                  dense
                  v-model.trim="member.subscriberMemberId"
                  :rules="requiredRules"
                  style="border-radius: 8px;"
                  @input="validateMemberId(member.subscriberMemberId, 'subscriberMemberId')"
                  :error-messages="errorMessages.subscriberMemberId"
                ></v-text-field>
                
              </div>

              <!-- FOOTER BUTTONS -->
              <div class="form_btn_container mb-10 mt-10">
                <v-btn class="modal_radius_btn no_box_shadow form_btn" color="#0069F3" text @click.prevent="areYouSure = true" >Cancel</v-btn>
                <v-btn class="modal_radius_btn no_box_shadow form_btn form_btn_valid" :disabled="!valid" @click.prevent="confirm()">Confirm Eligibility</v-btn>
              </div>

          </v-form>

          <div class="conrirmInfo_selectProvider" v-else>
            <div class="confirmInfo_selectProvider_cont">
              <div class="confirmInfo_title">Your account is created. Now let's select a provider</div>
              <div class="confirm_info_text">
                Select a provider, add family members and activate your membership by having your a health check in with your provider. 
              </div>
              <div class="dialog_buttons">
                <button class="dialog_single_button dialog_btn_warn" @click="selectProvider()" >
                  Select my provider
                </button>
              </div>
            </div>
          </div>
        </div>
          
      </div>

    </div>

    <!-- MODALS -->

    <!-- ENTER SUBSCRIBER DATA IF DEPENDENT CREATES ACCOUNT BUT SUBSCRIBER HAS NO ACCOUNT YET -->
    <v-dialog v-model.trim="needSubscriber" width="500">
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="needSubscriber = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Subscriber's Info
          </div>
          <div class="dialog_subtext dialog_second">
            Please, enter the Subscriber's Info
          </div>
          <div class="dialog_form mt-5">
            <v-form ref='subscriberForm' v-model="subscriberForm">

              <!-- First Name -->
              <div>
                <label>Firt Name</label>
                <v-text-field
                  placeholder="Enter subscriber's firt name here"
                  outlined
                  dense
                  v-model.trim="subscriber.firstName"
                  :rules="requiredRules"
                  style="border-radius: 8px;"
                ></v-text-field>
              </div>

              <!-- Last Name -->
              <div>
                <label>Last Name</label>
                <v-text-field
                  placeholder="Enter subscriber's last name"
                  outlined
                  dense
                  v-model.trim="subscriber.lastName"
                  :rules="requiredRules"
                  style="border-radius: 8px;"
                ></v-text-field>
              </div>

              <!-- Date of Birth -->
              <div>
                <label>Date of Birth</label>
                <v-menu ref="menu" hide-details v-model.trim="calendarMenu1" transition="scale-transition"
                  :close-on-content-click="false" offset-y min-width="auto" location="start" :nudge-bottom="10"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormattedSubscriber"
                      @input="parseDate(dateFormattedSubscriber, 'subscriber')"
                      outlined
                      v-mask="'##/##/####'"
                      style="border-radius: 8px;"
                      :rules="requiredRules"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      v-bind="attrs"
                      placeholder="MM/DD/YYYY"
                      v-on="on"
                      :error-messages="subscriberDobError"
                    ></v-text-field>
                  </template>
                </v-menu>
              </div>

              <!-- Gender -->
              <div>
                <label>Gender</label>
                <v-select v-model="subscriber.gender" :items="genders" 
                  placeholder="Select" item-text="text" item-value="value" 
                  :rules="requiredRules" outlined dense>
                </v-select>
              </div>

              <!-- Member ID -->
              <div>
                <label>Subscriber's Member ID</label>
                <v-text-field
                  placeholder="Enter Subsriber's Member ID"
                  outlined
                  dense
                  v-model.trim="member.subscriberMemberId"
                  :rules="requiredRules"
                  style="border-radius: 8px;"
                ></v-text-field>
              </div>
            </v-form>
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="needSubscriber = false" >
              Cancel
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" :disabled="!subscriberForm" @click="confirm()">
              Submit
            </button>
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- VERIFY EMAIL ADDRESS -->
    <v-dialog v-model.trim="checkEmailDialog" width="500">

      <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="checkEmailDialog = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_image">
            <img src="/svg/email_sent.svg" />
          </div>
          <div class="dialog_title dialog_primary">
            Verify your E-mail Address
          </div>
          <div class="dialog_subtext dialog_second">
            To start using Apaly we need to verify your email address. Please check it on
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="$router.push({ name: 'MemberLogin' })" >
              Back To Login
            </button>
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- CONFIRMING INFO -->
    <v-dialog v-model="checkingEligibility" width="500px" persistent>

      <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="checkingEligibility = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_image">
            <v-progress-circular indeterminate :size="50" color="primary" class="text-center mt-5 mb-5"></v-progress-circular>
          </div>
          <div class="dialog_title dialog_second text-center">
            We're confirming your eligibility
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- ELIGIBILITY CONFIRMED 1 -->
    <v-dialog v-model="success" width="500px" persistent>

       <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Eligibility Confirmed
          </div>
          <div class="dialog_subtext dialog_second" style="font-weight: bold;">
            Would you like to add family members?
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="goToMembershipCard()" >
              No. I'm all set
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="addFam()" >
              Yes
            </button>
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- Custom clinic redirect modal -->
    <v-dialog v-model="ifOneMedical" width="500px" persistent>

      <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Success! You're enrolled with {{ memberInfo && memberInfo.location ? memberInfo.location.name : ''}}
          </div>
          <div class="dialog_subtext dialog_second">
            <!-- {{ postMemberEnrollment !== null ? postMemberEnrollment.message : '' }} -->
            You now have access to your own personal provider! <br> <br>
            Now let's connect you to {{ memberInfo && memberInfo.location ? memberInfo.location.name : ''}} member portal, so you can create your patient account and schedule your first appointment 
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="goToCustomRedirect(postMemberEnrollment.redirect)" >
              Connect me now
            </button>
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- ELIGIBILITY CONFIRMED 2 -->
    <v-dialog v-model="successNoFam" width="500px" persistent>

      <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Success! You're enrolled with {{ memberInfo && memberInfo.location ? memberInfo.location.name : ''}}
          </div>
          <div class="dialog_subtext dialog_second">
            You now have access to your own personal provider! <br><br>
            The clinic will reach out to schedule your first appointment.  
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="redirect()" >
              Okay
            </button>
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- WRONG DATA ENTRY -->
    <v-dialog v-model="wrongDataEntry" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="reviseInfo()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_title_new dialog_error">
            Oops! We could not confirm eligibility.
          </div>
          <div class="dialog_subtext dialog_second">
            <!-- Please review and update the information to try again or contact support at <strong> support@apaly.com </strong> -->
            Please review and update the information to try again, or contact us at <strong>support@apaly.com</strong>
          </div>
          <div class="dialog_buttons_2">
            <button class="dialog_double_button dialog_btn_second " @click="areYouSure = true" >
              Fix Later
            </button>
            <button class="dialog_double_button dialog_btn_primary " @click="reviseInfo()" >
              Review information
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- MEMBER NOT ACTIVE -->
    <v-dialog v-model="memberNotActive" width="450px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="reviseInfo()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error" style="text-align: left;">
            Oops! It seems that you are not eligible on this plan.
          </div>
          <div class="dialog_subtext2 dialog_second" style="font-weight: bold;">
            Please review the information you entered to try again, or contact your health plan administrator to ensure you are eligible for this benefit.
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="redirect()">
              Fix Later
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="reviseInfo()" >
              Review information
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- SYSTEM ERROR -->
    <v-dialog v-model="systemError" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
            Oops! It seems our system is down
          </div>
          <div class="dialog_subtext dialog_second" style="font-weight: bold">
            Your account has been created and your information is saved. Login to the app later to confirm your eligibility.
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="redirect()" >
              Try again later
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Are you sure -->
    <v-dialog v-model="areYouSure" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="areYouSure = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error" style="text-align: left">
            Are you sure you want to cancel? 
          </div>
          <div class="dialog_subtext2 dialog_second bold_label" >
            Your account has been created, but your eligibility is still pending. To enroll with your provider, you will need to confirm your eligibility. If you need help, please contact us at support@apaly.com 
          </div>
          <div class="dialog_buttons">
            <!-- <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="accountCreated = true" > -->
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="redirect()" >
              Yes, cancel
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="areYouSure = false" >
              No, continue
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Account Created but not Eligibible -->
    <!-- <v-dialog v-model="accountCreated" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_second">
            Ok! <br> Your account is created,  but your eligibility is pending.
            You can confirm eligibility any time to enroll with your provider.
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="redirect()" >
              Got it
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- ALL SET -->
    <v-dialog v-model="chooseProvider" width="500px" persistent>

       <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Success! You're enrolled with {{ memberInfo && memberInfo.location ? memberInfo.location.name : ''}}
          </div>
          <div class="dialog_subtext dialog_second">
            You now have access to your own personal provider! <br><br>
            The clinic will reach out to schedule your first appointment.  
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="redirect()" >
              Okay
            </button>
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- ADD DEPENDENTS -->
    <!-- <v-dialog v-model="informativeModal" width="500px" persistent>

      <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary text-center">
            Add your dependents!
          </div>
          <div class="dialog_subtext dialog_second text-center">
            We see that you have a family plan. Add them to your account and select a DPC clinic.
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="redirect()" >
              Add later
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="openNewMemberModal()" >
              Add Family Member
            </button>
          </div>
        </v-card-text>

      </v-card>
    </v-dialog> -->

    <!-- NO REGISTRATION DATA -->
    <v-dialog v-model="noData" width="500px" persistent>

      <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="login()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
            {{ noDataMessage }}
          </div>
          <div class="dialog_subtext dialog_second">
            Please login again using your registered email earlier  
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="login()" >
              Okay
            </button>
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <errorModal v-if="somethingWrong" :someThingWentWrong="somethingWrong" :errorMess="erroMessage" ref="error_modal"></errorModal>

   <DependentFlow v-if="dependentModal"  @closeDependentModal="dependentModal = false"
   :dependentModal="dependentModal" />
   
  </div>
</template>
  
  <script>
  var _paq = window._paq || [];
_paq.push(['trackPageView']);
import api from "@/services/api";
import moment from "moment";
import { mapState } from 'vuex';
import errorModal from '../../../Common/SomethingWentWrong.vue';
import FullscreenLoader from '../../../Common/FullscreenLoader.vue';
import Mixpanel from "mixpanel-browser";

export default {
  components: {
    errorModal,
    FullscreenLoader
  },
  data() {
    return {
      subscriberForm: false, //form validation
      needSubscriber: false, //modal
      subscriber: {},
      selectProviderNow: false,
      memberNotActive: false,
      wrongDataEntry: false,
      systemError: false,
      // accountCreated: false,
      areYouSure: false,
      toRevise: false,
      loader: true,
      searchTerm: '',
      selectedEmployerInfo: null,
      employersCopy: [],
      allEmployers: [],
      employer: "",
      employers: [],
      searchEmp: "",
      showEmployer: false,
      empl: null,
      somethingWrong: false,
      erroMessage: '',
      noData: false,
      noDataMessage: "",
      postMemberEnrollment: null,
      ifOneMedical: false,
      successNoFam: false,
      dependentModal: false,
      memberErrorMsg: '',
      newMember:{
          // dob: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        },
      // user: {},
      informativeModal: false,
      hasFamily: false,
      providerId: null,
      locationId: null,
      checkingEligibility: false,
      chooseProvider: false,
      success: false,
      errorModal: false,
      dateFormatted: '',
      dateFormattedSubscriber: '',
      formattedPhoneNumber:'',
      selectedProvider: null,
      checkEmailDialog: false,
      filterMarket: {},
      member: {
        payerId: null
        // Why dob is initialized? it should only fill in by BE (T.N.)
        // dob: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      showPassword: false,
      showPasswordConfirmation: false,
      valid: false,
      emailErrorMessage: null,
      emailStatus: false,
      pdfUrl: "",
      pdfDialog: false,
      subscriberRelation: "",
      healthPlan: "",
      calendarMenu1: false,
      calendarMenu: false,
      memberFormattedPhoneNumber: '',
      memberDateFormatted: '',
      memberProviderId: '',
      memberLocationId: '',
      dependentId: '', reviseDependentInfo: false,
      memberDobError: null,
      subscriberDobError: null,
      // plans: [],
      plansList: [],
      palyerList: [
        {text: '1', value:'1'},
        {text: '2', value:'2'}
      ],
      genders:[
        {text: 'Female', value:'f'},
        {text: 'Male', value:'m'}
      ],
      relationships: [
        { text: "Self", value: "Self" },
         { text: "Spouse", value: "Spouse" },
        { text: "Dependent", value: "Dependent" },
      ],
      // emailRules: [
      //   (v) => !!v || "This field is required",
      //   (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      // ],
      requiredRules: [(v) => !!v || "This field is required"],
      // passRules: [
      //   (v) => !!v || "Password is required",
      //   (v) => !!v || "Please type password.",
      //   (v) => (v && v.length > 8) || "Minimum 8 characters",
      //   (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital letter",
      //   (v) =>
      //     (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
      // ],
      // confirmPassRules: [
      //   (v) => !!v || "Password is required",
      //   (v) => !!v || "Please type password.",
      //   (v) => (v && v.length > 8) || "Minimum 8 characters",
      //   (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital letter",
      //   (v) =>
      //     (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
      //   (v) => v == this.user.password || "Password must match",
      // ],
      phoneRules:[
        (v) => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      passwordMatches: true,
      error: "",
      eligibilityStep: 1,
        validInfo: false,
        validInsurance: false,
      confirmSemafor: true, addMemberSemafor: true,
      enrollmentStatus: false,
      selectedProvider: null,
      registrationData: null,
      errorMessages: {
        memberId: null,
        subscriberMemberId: null
      }
    };
  },
  computed: {
    ...mapState({
      plans: (state) => state.DPCMember.employerPlans,
      memberInfo: (state) => state.memberInfo,
    }),
    showPayerField() {
      return (this.member.payers && this.member.payers.length > 1);
    }
    // Get member data from store
  },
  methods: {
    changeSubscriberRelation() {
        if (this.member.subscriberRelation == 'Self') {
            this.errorMessages.subscriberMemberId = null;
        }
    },
    validateMemberId(value, field) {
      // Allow only alphanumeric characters and hyphen (-)
      const isValid = /^[a-zA-Z0-9-]*$/.test(value);

      if (isValid) {
        this.errorMessages[field] = null
      } else {
        this.errorMessages[field] = 'Special characters not allowed'
      }
    },
    selectProvider() {
      // console.log("Select Provider");
      // console.log(this.memberInfo.id);
      this.getMemnerInfo();
      this.$store.commit("setDependent", this.memberInfo.id);
      this.$store.dispatch('getMarketplace', this.$route.query); // K.N idk what this is for
      this.$router.push('/marketplace')
    },
    reviseInfo() {
      // this.errorModal = false;
      this.memberNotActive = false;
      this.wrongDataEntry = false;
      this.systemError = false;
      this.toRevise = true;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    closeModal() {
      this.$refs.error_modal.closePopUp();
    },
    redirect() {
      this.getMemnerInfo();
      //Get redirect destination from localStorage
      let redirect = localStorage.getItem('redirect');

      //See where to redirect
      if(redirect == "/home") {
        this.$router.push('/home')
      }
      else if(redirect == "/account/details") {
        this.$router.push('/account/details')
      }
      else {
        // console.log("Redirect is empty: ", redirect);
        this.$router.push('/marketplace');
      }
    },
    login() {
      this.$router.push('/login');
    },
    // goToHome() {
    //   window.location = '/home';
    // },
    goToCustomRedirect(redirect) {
      this.getMemnerInfo();
      window.open(redirect, '_blank');
      this.goHome();
    },
    goToMembershipCard() {
      this.$router.push('/membership').then(() => {
        window.location.reload();
      });
    },
    getMemnerInfo() {
      // Dispatch memberInfo
      this.$store.dispatch('getMemberInfo');
    },
    async checkCustomModal() {
      // Hide the current modal first
      this.success = false;

      // console.log("checkCustomModal()");
      // console.log(this.providerId);

      await this.getMemnerInfo();

      // if user will not add family members, or clicks on 'Im all set',
      // frontend needs to call this endpoint to determine if we need to
      // show the custom modal with redirect link or not
      await api()
        .get(`/member/app/provider/${this.providerId}/postenrollment`) // this.providerId is populated in created
        .catch((err) => {
          // We should handle error, soon
          // Ex. There was an error while retrieving your post enrollment data.
          // Please contact support, thank you.
          console.log("Custom Modal Error");
          console.log(err.response.data);

          // TEMPORARY: If there's an error, go to default function
          this.allSet();
        })
        .then((res) => {
          // console.log(res);
          if(res) {
            if(res.data.postMemberEnrollment !== null) {
              this.postMemberEnrollment = res.data.postMemberEnrollment;
              // this.ifOneMedical = true;
              // console.log("With Redirect link");

              // If subscriber has NO family plan, then go to Membership Card page else, go to allSet();
              if(this.memberInfo.hasFamily == false) {
                this.goToMembershipCard();
              }
              else {
                this.allSet();
              }
            }
            else {
              // console.log("Custom Modal Empty???");
              // If custom modal isn't needed to be displayed
              // If subscriber has NO family plan, then go to Membership Card page else, go to allSet();
              if(this.memberInfo.hasFamily == false) {
                this.goToMembershipCard();
              }
              else {
                this.allSet();
              }
            }
          }
          else {

            // console.log("No Custom Modal");
            // And if no provider was selected
            // If custom modal isn't needed to be displayed
            this.allSet();
          }
        });
    },
   allSet(){
      this.success = false;
      // this.chooseProvider = true;
      this.successNoFam = true;
   },
    cancelNewMember(){
      this.dependentModal = false;
      // this.$router.push('/marketplace') // T.N. I think we should redirect to home
      this.$router.push('/home')
    },
    reviseMember(){
      this.memberErrorModal = false;
      this.dependentModal = true;
    },
    getaccountInformation(){
      // Only get the account info if the following fields are not empty
      // This is to avoid triggering the input fields warning when the page just loaded
      if(this.memberInfo.firstName || this.memberInfo.lastName || this.memberInfo.gender || this.memberInfo.memberId) {
        // Get member info from state/computed
        // console.log("Complete");
        this.member.firstName = this.memberInfo.firstName ? this.memberInfo.firstName : null;
        this.member.lastName = this.memberInfo.lastName ? this.memberInfo.lastName : null;
        this.member.gender = this.memberInfo.gender ? this.memberInfo.gender : null;
        this.member.memberId = this.memberInfo.memberId ? this.memberInfo.memberId : null;
        this.dateFormatted = this.memberInfo.dob ? moment.utc(this.memberInfo.dob).format("MM/DD/YYYY") : '';
        this.formattedPhoneNumber = this.memberInfo.phone ? this.memberInfo.phone : '';
        this.member.subscriberRelation = this.memberInfo.subscriberRelation ? this.memberInfo.subscriberRelation : null;
      }
      this.member.planId = this.memberInfo.plans ? this.memberInfo.plans[0].planId : null;
      this.member.payers = this.memberInfo.payers ? this.memberInfo.payers : null;
      this.member.payerId = this.memberInfo.payers ? this.memberInfo.payers[0].payerId : null;
      this.providerId = this.memberInfo.providerId ? this.memberInfo.providerId : null;
      this.locationId = this.memberInfo.locationId ? this.memberInfo.locationId : null;
      this.selectedEmployerInfo = this.memberInfo.employer

      if (this.selectedEmployerInfo && this.selectedEmployerInfo.groups) {
        const group = this.selectedEmployerInfo.groups.filter(group => {
            return group.tpa?.payerId === this.member.payerId
        })

        if (group && group[0]) {
            this.member.groupId = group[0].groupId
        }
      }
    },
    openNewMemberModal(){
      this.informativeModal = false;
      // this.addMemberModal = true;
      this.dependentModal = true;
      this.newMember.companyName = this.memberInfo.companyName;
      this.newMember.planId = this.memberInfo.planId;
      this.newMember.payerId = this.memberInfo.payerId;
      this.newMember.groupId = this.memberInfo.groupId;
      //this.newMember.memberId = this.user.memberID;
    },
    // getProvider(provider,location) {
    //     api().get(`/member/app/market/provider/${provider}?locationId=${location}`).then((res) => {
    //     if (res) {
    //       console.log("getProvider", res.data);
    //       this.selectedProvider = res.data;
    //     }
    //   }); 
    // },
    getHomeInformation() {
      // console.log("getHomeInformation");
      api()
        .get(`/member/app/home`)
        .then((res) => {
          console.log("/member/app/home: ", res.data);
          if (res) {
            this.providerId = res.data.providerId;
            this.locationId = res.data.locationId;
            if(res.data.providerId && res.data.locationId){
              api().get(`/member/app/market/provider/${this.providerId}?locationId=${this.locationId}`).then((res) => {
                if (res) {
                  this.selectedProvider = res.data;
                  this.loader = false;
                }
              }); 
            }
            else {
              // console.log("No Selected Provider");
              this.loader = false;
            }
          }
        })
        .catch((err) => {
          this.loader = false;
          console.log("getHomeInformation() Error: ", err);
          alert(err.response.data);
        });
    },
    addFam(){
      this.getMemnerInfo();
      this.$router.push('/add-family-member')
    },
    goToClinicDetails(){
      window.location = `/marketplace/provider/details/${this.providerId}?location=${this.locationId}`;
    },
    goToMarketplace(){
      // Refactored by T.N. (see example on login)
      if(this.member.providerId != null && this.member.locationId != null){
        window.location = `/marketplace/provider/details/${this.member.providerId}?location=${this.member.locationId}`;
      }else{
        let filterMarket = this.member.filterMarket
        let companyFilter = filterMarket && filterMarket.company ? filterMarket.company : null
        let locationFilter = filterMarket && filterMarket.location ? filterMarket.location : null

        let url = `/marketplace`
        if(filterMarket){
          url += `?${companyFilter ? 'company=' + companyFilter :''}`
          if(locationFilter)url += `${companyFilter ? '&':''}location=${locationFilter}`
        }
        window.location = url
      }
    },
    goHome() {
      this.ifOneMedical = false;
      window.location = '/home';
    },
    // gotIt(){
    //   if(this.hasFamily){
    //     this.chooseProvider = false;
    //     this.informativeModal = true;
    //   }else{
    //       if(this.providerId != null && this.locationId != null){
    //         window.location = `/marketplace/provider/details/${this.providerId}?location=${this.locationId}`;
    //       }else{
    //         window.location = '/home';
    //       }
    //     }
    // },
    nextEligibilityStep(){
      this.eligibilityStep = 2;
    },
    getPlans() {
      // console.log('getPlans')
      return
      api()
        .get(`/member/app/plans`)
        .then((res) => {
          if (res) {
            this.plans = res.data;
          }
        })
        .catch((err) => {
          if (err.response.data) {
            this.error = err.response.data;
          } else {
            this.error = "Something went wrong. Please try again later.";
          }
        });
    },
    confirm(){
      if (!this.member.dob) {
        this.$refs.menuDob.validate()
        return 
      }

      this.checkingEligibility = true;
      this.valid = false;
      
      let data = {
        firstName: this.member.firstName,
        lastName: this.member.lastName,
        dob: this.member.dob,
        gender: this.member.gender,
        memberId: this.member.memberId,
        planId: this.member.planId,
        payerId: this.member.payerId,
        groupId: this.member.groupId,
        phone: this.member.phone,
        subscriberRelation: this.member.subscriberRelation,
        subscriberMemberId: this.member.subscriberMemberId,
        employerId: this.selectedEmployerInfo.id
      }

      // IF subscriber;s info was neeeded and filled, add data to payload
      if(this.needSubscriber == true && this.subscriber) {
        data = {
          ...data,
          subscriber: {
            ...this.subscriber,
            memberId: this.member.subscriberMemberId
          }
        }
        // Hide Modal
        this.needSubscriber = false;
      }
      if(!this.confirmSemafor)return
      this.confirmSemafor = false
  
      api()
        .post(`/member/app/confirm/info`, data)
        .then((res) => {
          if (res) {

            // Get time stamp with MongoDB timestamp format (2022-03-11T15:30:00.000Z)
            const confirmed_timestamp = new Date().toISOString();
            // Track Confirmed Eligibility: SUCCESS
            Mixpanel.track("member_eligibility_confirmed", {
              user_id: this.memberInfo.id, 
              timestamp: confirmed_timestamp,
              employer: this.selectedEmployerInfo,
              email: this.member.email,
              success: true,
              error_reason: "",
              source: "member_app" // member_app or dpc_portal
            });

            // Put the Plan info to store so the members can use it
            // MEM 464 | Populate plan field for second dependent being added
            this.$store.commit('setMemberPlan', this.member.planId);

            if(res.data.eligibility && res.data.eligibility.code == 200){
              // console.log("Eligible ", res.data.eligibility.code);
              this.checkingEligibility = false;
              this.providerId = res.data.providerId;
                this.locationId = res.data.locationId
              if(res.data.providerId != null && res.data.locationId != null){
                this.providerId = res.data.providerId;
                this.locationId = res.data.locationId
                //  this.chooseProvider = true;
                this.enrollmentStatus = true;
                this.$store.commit("setEnrollmentStatus", this.enrollmentStatus);
                // this.$router.push(`/marketplace/provider/details/${res.data.providerId}?location=${res.data.locationId}`)
              }
              // console.log("Next Step ", res.data.nextStep);
              if(res.data.nextStep == 'selectDPC'){
                // Must select provider
                this.selectProviderNow = true;
                // if(res.data.hasFamily == true && this.member.subscriberRelation == 'Self' ){ // Subscriber Sign up
                //   this.success = true;
                // }
                // else if(res.data.hasFamily == true && this.member.subscriberRelation != 'Self' ){ // Dependent Sign up
                //   this.successNoFam = true;
                // }
              }
              else if(res.data.nextStep == 'complete'){
                if(res.data.hasFamily == true && this.member.subscriberRelation == 'Self' ){ // Subscriber Sign up
                  this.success = true;
                }
                else if(res.data.hasFamily == true && this.member.subscriberRelation != 'Self' ){ // Dependent Sign up
                  // this.successNoFam = true;

                  this.goToMembershipCard() // Go directly to membership cards
                } else if (res.data.hasFamily === false) {
                    this.goToMembershipCard() // Go directly to membership cards
                }
                this.enrollmentStatus = true;
                this.$store.commit("setEnrollmentStatus", this.enrollmentStatus);
                // this.$router.push(`/marketplace/provider/details/${res.data.providerId}?location=${res.data.locationId}`)

              }
              else if(res.data.nextStep == 'confirmPlan') {
                // K.N - This should not happen as the eligibility has already been checked and successful
                console.log("Note: This should not happen as the eligibility has already been checked and successful");
              }
              this.filterMarket = res.data.filterMarket;
              this.hasFamily = res.data.hasFamily;
            }
            else{
              this.checkingEligibility = false;
              // console.log("res.eligibility: ", res.data.eligibility);

              // Custom Error Messages
              if(res.data.eligibility && res.data.eligibility.code == 401) {
                this.memberNotActive = true;
              }
              else if(res.data.eligibility && res.data.eligibility.code == 400) {
                this.wrongDataEntry = true;
              }
              else if(res.data.eligibility && res.data.eligibility.code == 500) {
                this.systemError = true;
              }
            }
          }
          else {
            this.errorModal = true;
            this.erroMessage = "Sorry, something went wrong while confirming your data...";
            console.log("/confirm/info: Empty Response");
          }
        })
        .catch((err) => {
          if (err) {
            this.checkingEligibility = false; // Hide modal

            if(err.response.status == 400 && err.response?.data?.message === 'We were unable to verify eligibility. Please enter subscriber information and resubmit.') {
              this.needSubscriber = true; // Allow users to add Subscriber Data
            } else {
              this.somethingWrong = true;
              this.erroMessage = err.response?.data?.message || "Sorry, something went wrong while confirming your data...";

              // Get time stamp with MongoDB timestamp format (2022-03-11T15:30:00.000Z)
              const confirmed_timestamp = new Date().toISOString();
              // Track Confirmed Eligibility: FAILED
              Mixpanel.track("member_eligibility_confirmed", {
                user_id: this.memberInfo.id, 
                timestamp: confirmed_timestamp,
                employer: this.selectedEmployerInfo,
                email: this.member.email,
                success: false,
                error_reason: err.response.data.message ? err.response.data.message : err.response.data,
                source: "member_app" // member_app or dpc_portal
              });
            }
          }
        })
        .finally(() => {
          this.confirmSemafor = true;
          this.valid = true;
        })
    },
    navigateTo(link) {
      this.$router.push({ name: link });
    },
    formatPhoneNumber(event) {
      this.member.phone = event
      this.formattedPhoneNumber = this.formatAsPhoneNumber(this.member.phone);
    },
    formatAsPhoneNumber(value) {
      value = value.replace(/\D/g, "");
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      value = value.slice(0, 3) + " " + value.slice(3, 6) + " " + value.slice(6);
      return value;
    },
    memberFormatPhoneNumber(event) {
      this.newMember.phone = event
      this.memberFormattedPhoneNumber = this.memberFormatAsPhoneNumber(this.newMember.phone);
    },
    memberFormatAsPhoneNumber(value) {
      value = value.replace(/\D/g, "");
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      value = value.slice(0, 3) + " " + value.slice(3, 6) + " " + value.slice(6);
      return value;
    },
    parseDate (date, field) {
      if (!date || date == "") return null

      const [month, day, year] = date.split('/');

      if (!month || !day || !year) {
        return null
      }

      if (year.length !== 4) {
        if (field === 'member') {
          this.memberDobError = 'Invalid date format'
        } else if (field === 'subscriber') {
          this.subscriberDobError = 'Invalid date format'
        }
      } else {
        if (field === 'member') {
          this.memberDobError = null;
        } else if (field === 'subscriber') {
          this.subscriberDobError = null
        }
      }

      const formatted = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`

      if (field === 'member') {
        this.member.dob = formatted;
      } else if (field === 'subscriber') {
        this.subscriber.dob = formatted
      }

      return formatted
    },
    searchingEmployers(e) {
      if (!this.searchTerm) {
        this.employersCopy = this.employers;
      }

      this.employers = this.employersCopy.filter((data) => {
        // console.log(data.companyName.toLowerCase().includes(this.searchTerm.toLowerCase()));
        if (this.searchTerm) {
            return data.companyName.toLowerCase().includes(this.searchTerm.toLowerCase());
        } else {
            return true
        }
      });

      if(this.searchTerm == '') {
        this.employers = this.allEmployers;
        this.employersCopy = this.allEmployers;
        // this.getEmployers();
      }
    },
    selectedEmployer() {
      // this.getEmployers();
      this.employers = this.allEmployers;
      this.employersCopy = this.allEmployers;
      
      // console.log(this.selectedEmployerInfo);

      this.user.searchEmp = this.selectedEmployerInfo.companyName;
      this.empl = this.selectedEmployerInfo;
    },
    getEmployers() {
      api()
        .get("/member/app/potential/employers")
        .then((res) => {
          this.employers = res.data;
          this.employersCopy = res.data;
          this.allEmployers = res.data;
        });
    },
    getMemberGroupId(newPayerId) {
        if (this.memberInfo.employer && this.memberInfo.employer.groups) {
            const group = this.memberInfo.employer.groups.filter(group => {
                return group.tpa?.payerId === newPayerId
            })

            if (group && group[0]) {
                this.member.groupId = group[0].groupId
            }
        }
    }
  },
  watch: {
    "member.dob" (val) {
      this.dateFormatted =  moment.utc(this.member.dob).format("MM/DD/YYYY");
    },
    "newMember.dob" (val) {
      this.memberDateFormatted =  moment.utc(this.newMember.dob).format("MM/DD/YYYY");
    },
    memberInfo(val){
      this.member = val;
    },
    'member.payers'(newPayers, oldPayers) {
      if (newPayers && newPayers.length === 1 && (!oldPayers || oldPayers.length !== 1)) {
        this.member.payerId = newPayers[0].payerId;
      }
    },
    'member.payerId'(newPayerId, oldPayerId) {
      if (newPayerId && newPayerId !== oldPayerId) {
        const selectedPayer = this.member.payers.find(payer => payer.payerId === newPayerId);
        if (selectedPayer && selectedPayer.plans && selectedPayer.plans.length === 1) {
            this.member.planId = selectedPayer.plans[0].planId;
        } else {
            this.member.planId = null;
        }

        this.getMemberGroupId(newPayerId);

        if (selectedPayer && selectedPayer.plans && selectedPayer.plans.length > 0) {
            this.plansList = selectedPayer.plans;
        } else {
            this.plansList = selectedPayer.plans;
        }
        }
    },
    'memberInfo.employer'(newEmployer) {
        this.selectedEmployerInfo = newEmployer;
    },
  },
  mounted() {
    // SHOW SIDE BAR
    this.$store.commit("showSidebar", this.memberInfo && this.$cookies.get("token"))
    this.employersCopy = [...this.employers];

  },
  created() {
    // TEMPORARY
    // this.$store.commit("showSidebar", localStorage.getItem("user") && this.$cookies.get("token"))
    

    // setTimeout(() => {
    //   if(localStorage.getItem('user')){
    //    this.member = JSON.parse(localStorage.user);
    //    this.member.subscriberRelation = 'Self';
    // }
    // }, 100);
   
   
    // if (this.$matomo && this.$matomo.trackPageView) {
    //   this.$matomo.trackPageView({
    //     customTitle: 'Member Confirm Information', // Optional
    //   });
    // } else {
    //   console.error("Matomo object or trackPageView method is not defined.");
    // }

    // Get selected provider
    this.selectedProvider = this.$store.getters.getSelectedProvider;
    // Get regsitered emaail and employer
    this.registrationData = this.$store.getters.getRegistrationData;

    // console.log("selectedProvider: ", this.$store.getters.getSelectedProvider);
    // console.log("registrationData: ", this.$store.getters.getRegistrationData);
    // console.log("loader: ", this.loader);

    // console.log("Registration Data:");
    // console.log(this.registrationData);
    // console.log("===========================");

    // console.log("Provider Data:");
    // console.log(this.selectedProvider);
    // console.log("===========================");
    
    // setTimeout(() => {
    //   this.getPlans();
    // }, 1000);
    if (this.memberInfo && this.$cookies.get("token")){
      this.getHomeInformation()
      this.getaccountInformation()
    }
    else {
      if(!this.$store.getters.getSelectedProvider && !this.$store.getters.getRegistrationData) {
        // console.log("getSelectedProvider and getRegistrationData are empty");
        this.loader =  false;
        this.noData = true;
        this.noDataMessage = "No Selected Provider and Registration Data"
      }
      else if(this.$store.getters.getSelectedProvider && !this.$store.getters.getRegistrationData) {
        // console.log("getSelectedProvider is empty");
        this.loader =  false;
      }
      else if(!this.$store.getters.getSelectedProvider && this.$store.getters.getRegistrationData) {
        // console.log("getRegistrationData is empty");
        this.loader =  false;
      }
      else if (this.$store.getters.getSelectedProvider && this.$store.getters.getRegistrationData) {
        // console.log("getSelectedProvider and getRegistrationData are NOT empty");
        this.loader =  false;
      }
    }
    this.getEmployers();
    // console.log("token", this.$cookies.get("token"));
  
  },
};
</script>
  <style scoped lang="scss">
  .sign_header{
    // padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 75px !important;
  }
  label{
    color: #333333 !important;
    font-weight: 400; 
  }
  .logo-style{
    display: block !important;
  }

.v-input__control{
    margin-bottom: 0px !important;
  }

.apaly-logo {
  width: 130px;
}
.register-page-container {
  margin: 0 auto;
}
.register-page-logo {
  left: 30px;
}
.register-page-title {
  text-align: center;
}
.register-page-footer {
  padding: 0 140px;
  margin-top: 10px;
}
.register-page-error-box {
  padding: 20px 140px;
}
.authentication-page-left-side {
  background-color: #214a82;
  padding: 50px 100px 0px 100px;
  flex-direction: column;
  justify-content: space-between;
  &-image {
    border: 30px solid rgba(255, 255, 255, 0.1);

    border-radius: 10px;
  }
  &-logo {
    img {
      width: 120px;
    }
  }
  &-text {
    color: white;
  }
  &-footer-text {
    color: white;
  }
}
.authentication-page-right-side {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.form-button {
  display: flex;
  justify-content: flex-end;
  .primary {
    flex: 0.5;
  }
}
.link-text {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .apaly-logo{
    height: 40px;
    width: 70px;
  }
  .hide-desktop{
  display: flex !important;
}
  .hide-mobile{
    display: none !important;
  }
  .logo-style{
    display: flex !important;
    justify-content: space-between;
   
  }
  .router-view {
    padding-top: 0px !important;
  }
  .register-page-logo {
    position: inherit;
  }
  .register-page {
    padding: 24px;
    margin-top: 0px;
  }
  .register-page-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .register-page-container {
    width: 100%;
  }
  .register-page-footer {
    padding: 0;
    margin-top: 20px;
  }
  .register-page-error-box {
    padding: 10px 0;
  }
  .authentication-page-right-side-form {
    width:100%;
    padding: 0;
  }
  .authentication-page-right-side {
    flex-direction: column-reverse;
  }
}
</style>
  