<template>
  <div>
    <div class="profile_main_content">

      <!-- HEADER -->
      <div class="profile_header_container" v-if="!loggedIn">
        <div class="profile_header_div display_flex display_flex_left">
          <img class="profile_logo" src="/apaly-logo.png" height="40px" width="auto">
        </div>
        <div class="profile_header_div display_flex display_flex_right">
          <v-btn @click="$router.push('/login')" color="primary" class="profile_hreader_btn">
            Login 
          </v-btn>
        </div>
      </div>

      <!-- Spacer for when user is NOT logged in -->
      <div style="width: 100%; height: 80px;" v-if="!loggedIn"></div>

      <!-- Spacer for when user is logged in -->
      <div style="width: 100%; height: 20px;" v-else></div>

      <!-- MAIN INFO -->
      <div class="profile_main_info"> 
        <div class="profile_header_div">
          <!-- BACK BUTTON AND BADGE -->
          <div class="profile_row">
            <!-- BACK BUTTON -->
            <div class="profile_header_div">
              <div @click="backToMarketplace()" role="button" class="display_flex display_flex_mid" >
                <img src="/svg/backArrow.svg" width="16" />
                <p class="ma-0 ml-2">Back</p>
              </div>
            </div>
            <div class="profile_header_div display_flex display_flex_mid display_flex_right text_nowarp">
              Trusted Partner &ensp; <img src="/svg/apaly_badge.svg" width="40" height="auto" />
            </div>
          </div>

          <!-- PROFILE PICTURE AND NAME -->
          <div class="profile_card_header">
            <!-- LOGO -->
            <div class="marketplace_item_logo" :style="`background-image:url(${clinician?.logo ? clinician?.logo : clinician?.gender == 'F' ? '/clinician_f.png':'/clinician_m.png'})`"></div>
            &emsp;
            <!-- Clinician Name -->
            <div class="marketplace-item-content-name card_title">
                {{ clinician?.providerName }}, {{clinician?.credentials }}
            </div>
          </div>

          <!-- CATEGORY -->
          <div class="profile_row display_flex_left">
            <div class="display_flex display_mid">
              <img class="mr-2 card_icon" src="/svg/notes_medical.svg" />
              <p class="ma-0 card_text"> {{ clinician?.category }}</p>
            </div>
          </div>

          <!-- NEXT DAY APPOINTMENT -->
          <div class="profile_row display_flex_left">
            <div class="display_flex display_mid">
              <img class="mr-2 card_icon" :src="clinician?.newPatients ? '/svg/calendar_alt.svg' : '/svg/info_circle.svg'" /> 
              <p v-if="clinician?.newPatients" class="ma-0 card_text"> Next day appointments typically available</p>
              <p v-else class="ma-0 card_text"> Not accepting new patients</p>

            </div>
          </div>

          <!-- PROFILE ICON TEXTS -->
          <div class="profile_row_block">
            <div class="profile_icon_text">
              <div class="display_flex display_flex_mid">
                <img class="mr-2 card_icon" src="/svg/hospital.svg"/>
                &nbsp; At-Clinic
              </div>
            </div>
            <div class="profile_icon_text">
              <div class="display_flex display_flex_mid">
                <img class="mr-2 card_icon" src="/svg/telephone.svg"/>
                &nbsp; Phone
              </div>
            </div>
            <div class="profile_icon_text">
              <div class="display_flex display_flex_mid">
                <img class="mr-2 card_icon" src="/svg/laptop.svg"/>
                &nbsp; Virtual
              </div>
            </div>
            <div class="profile_icon_text">
              <div class="display_flex display_flex_mid">
                <img class="mr-2 card_icon" src="/svg/briefcase.svg"/>
                &nbsp; At Work
              </div>
            </div>
            <div class="profile_icon_text">
              <div class="display_flex display_flex_mid">
                <img class="mr-2 card_icon" src="/svg/chat.svg"/>
                &nbsp; Text/Chat
              </div>
            </div>
          </div>

          <!-- CONFIRMATION BUTTON -->
          <!-- <div class="profile_row">
            <div class="profile_badge_container display_flex display_flex_mid">
                <img src="/svg/dollar_badge.svg" width="35" height="auto" class="mr-3"/>
                <p class="mb-0"> Select this clinician and then we'll confirm that your employer pays for this benefit</p>
                <img src="/svg/chevron_right.svg" width="35" height="auto" class="mr-3"/>
            </div>
          </div> --> 

        </div>

        <!-- EMPTY SPACE -->
        <div class="profile_header_div profile_mobile_hide">
          
        </div>
      </div>

      <!-- TABS -->
      <div class="profile_main_row">
        <br> <!-- SPACER -->
        <div class="profile_row">
          <div class="profile_tab_con display_flex display_flex_mid">
            <div class="profile_tab_div text_nowarp" v-for="(tab, t) in profileTab" :key="'tab'+t">
              <div :class="`profile_tab ${tab.status == 'open'? 'profile_tab_active':''}`" @click="goToSection(tab)">
                {{ tab.tabName }}
              </div>
            </div>
          </div>
        </div>
        <div class="profile_line"></div>  <!-- LINE -->
        <br> <!-- SPACER -->
      </div>

      <!-- DECRIPTION-->
      <div id="tab_info" class="profile_main_info mb-10">
        <!-- DECRIPTION -->
        <div class="profile_header_div">
          <div class="profile_info_subtitle mb-2">Description:</div>

          <!-- DISPLAYING LESS -->
          <div v-if="descLength" class="profile_info_text profile_desc_container" :style="descLength ? 'height:auto' : ''">
                {{ clinician?.details?.description.substring(0,200) }}...
              <br><br>
              <small class="profile_display_btn" @click="descLength = false">
                show more <v-icon small color="primary">mdi-chevron-down</v-icon>
              </small>
          </div>

          <!-- DISPLAYING MORE -->
          <div v-else-if="!descLength  && clinician?.details?.description!=null && clinician?.details?.description.length > 300 " class="profile_info_text profile_desc_container" style="height:auto">
                {{ clinician?.details?.description }}
              <br><br>
              <small class="profile_display_btn">
                show less <v-icon small color="primary">mdi-chevron-up</v-icon>
              </small>
            </div>

          <!-- NORMAL DISPLAY -->
          <div  class="profile_info_text profile_desc_container" style="height:auto">
            {{ clinician?.details?.description }}
          </div>

          <!-- GENDER -->
          <div class="profile_row_2">
            <div class="profile_info_subtitle mb-1">Gender:</div>
            <div class="profile_info_text" style="height:auto">
              {{ clinician?.details?.gender == 'F' ? 'Female':'Male' }}
            </div>
          </div>

          <!-- NPI -->
          <div class="profile_row_2">
            <div class="profile_info_subtitle mb-1">NPI:</div>
            <div class="profile_info_text" style="height:auto">
              {{ clinician?.details?.NPI }}
            </div>
          </div>

          <!-- EXPERIENCE -->
          <!-- <div class="profile_row_2">
            <div class="profile_info_subtitle mb-1">Phone Number:</div>
            <div class="profile_info_text" style="height:auto">
              {{ clinician?.contact?.phone }}
            </div>
          </div> -->
        

        </div>

      
      </div>

      <!-- SERCVICES -->
      <div id="tab_services" class="profile_main_row mb-10">
        <div class="profile_info_title mb-2">Services</div>
        <div class="profile_category_container">

          <!-- CORE SERVICES OFFERED -->
          <div class="profile_cont_div">
            <div class="profile_icon_text_2">
              <img src="/svg/heart_circle.svg" width="40" height="auto" /> &nbsp; Core Services Offered
            </div>
            <div class="mt-2">
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Wellness Checks and Preventative Care</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Chronic Disease Management</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">24/7 Access to Manage your Care </p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Sick Care </p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Mental Health Guidance </p>
              </div>
            </div>
          </div>

          <!-- ADDITIONAL COVERED SERVICES -->
          <div class="profile_cont_div" style="display: none;">
            <div class="profile_icon_text_2">
              <img src="/svg/heart_circle.svg" width="40" height="auto" /> &nbsp; Additional Covered Services
            </div>
            <div class="mt-2">
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Nutritionists</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Pharmacy</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Flu Shots and Select Vaccines</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Pediatrics</p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- CARE MODALITY -->
      <div class="profile_main_row mb-10">
        <div class="profile_info_title mb-2">Care Modality</div>

        <!-- CORE SERVICES OFFERED -->
        <div class="profile_category_container">

          <!-- AT-CLINIC -->
          <div class="profile_cont_div">
            <div class="profile_icon_text_3">
              <img class="mr-2 profile_modality_icon" src="/svg/hospital.svg"/>
              <div class="profile_modality_content">
                At-Clinic
                <br>
                <div class="profile_modality_text">
                  Visit your provider face-to-face at the clinic
                </div>
              </div>
            </div>  
          </div>

          <!-- VIRTUAL -->
          <div class="profile_cont_div">
            <div class="profile_icon_text_3">
              <img class="mr-2 profile_modality_icon" src="/svg/laptop.svg"/>
              <div class="profile_modality_content">
                Virtual
                <br>
                <div class="profile_modality_text">
                  Meet through a video visit
                </div>
              </div>
            </div>  
          </div>

          <!-- TEXT/CHAT -->
          <div class="profile_cont_div">
            <div class="profile_icon_text_3">
              <img class="mr-2 profile_modality_icon" src="/svg/chat.svg"/>
              <div class="profile_modality_content">
                Text/Chat
                <br>
                <div class="profile_modality_text">
                  Chat/Text with your provider
                </div>
              </div>
            </div>  
          </div>

          <!-- PHONE -->
          <div class="profile_cont_div">
            <div class="profile_icon_text_3">
              <img class="mr-2 profile_modality_icon" src="/svg/telephone.svg"/>
              <div class="profile_modality_content">
                Phone
                <br>
                <div class="profile_modality_text">
                  Talk on the phone
                </div>
              </div>
            </div>  
          </div>

          <!-- AT WORK -->
          <div class="profile_cont_div">
            <div class="profile_icon_text_3">
              <img class="mr-2 profile_modality_icon" src="/svg/briefcase.svg"/>
              <div class="profile_modality_content">
                At Work
                <br>
                <div class="profile_modality_text">
                  See your provider at work (when they're there)
                </div>
              </div>
            </div>  
          </div>


        </div>
      </div>

      <!-- PROVIDERS -->
      <div id="tab_locations" class="profile_providers_layout pl-0 pr-0" >

        <div class="profile_providers_layout mb-0" >
          <div class="profile_info_title mb-2">Locations</div>
        </div>

        <div class="profile_main_info_3">

          <!-- MAP MOBILE -->
          <!-- <div class="profile_header_div profile_desktop_hide">
            <div class="profile_row_2 profile_map mt-0">
              <GmapMap :center="userCenter" :zoom="18" class="profile_map_size">
                <GmapMarker :position="userCenter"  @click="openCard(userCenter)"> </GmapMarker>
                <GmapMarker 
                  v-for="(place, index) in clinician?.clinics" 
                  :key="index" 
                  :position="place.coordinates" 
                  @click="openCard(place)" 
                  :icon="{ url: 'dark-blue.png', scaledSize: { width: 40, height: 60 } }"
                />
                <GmapInfoWindow :position="infoWindowPosition"  :opened="infoWindowOpened"  @closeclick="closeInfoWindow">
                  <v-card class="">
                      <div>{{ returnCapitalizedName(clinician?.clinics?.providerName) }}</div>
                  </v-card>
                </GmapInfoWindow>
              </GmapMap>
            </div>
            <div class="profile_cursor display_flex display_flex_mid profile_color_primary mb-2 mt-2" @click="getDirections()">
              Get directions on Google Maps &nbsp; <img src="/svg/chevron_right.svg" class="ml-1" height="18"/>
            </div>
          </div> -->
          
          <!-- CARDS -->
          <div class="profile_header_div_2">

            <div class="" v-if="clinician?.clinics.length <= 0"> 
              <strong>{{ returnCapitalizedName(clinician?.providerName) }}</strong> &nbsp;
              haven't listed any clinics, yet.
            </div>

            <!-- PROVIDER -->
            <div class="profile_main_info_2 mb-10" style=" ">

            <div class="profile_provider_card_layout_2" v-for="(providers, c) in clinician?.clinics" :key="'c'+c">
              <div class="profile_provider_card_header">
                <div class="profile_provider_card_image" :style="`background-image:url(${providers?.logo ? providers.logo : '/clinic_image.png'})`">
                </div>
                &emsp;
                <div class="profile_provider_card_name">
                    {{ providers?.providerName }}
                </div>
              </div>
              <div class="provider_icon_text">
                <img class="mr-2" width="20px" height="auto" src="/svg/cardLocation.svg"/>
                &nbsp;
                <div v-if="providers.address"> {{ providers.address }}&nbsp;{{ providers.city }},&nbsp;{{ providers.state }}&nbsp;{{ providers.zipCode }} </div>
                <!-- <div v-else>  N/A </div> -->
                <div v-else>  {{`${providers.address1}, ${providers.city}, ${providers.state} ${providers.zip}`}} </div>
              </div>
              <div v-if="providers.address && providers.address !== null" class="provider_icon_text distance_chip">
                <img class="mr-2" width="20px" height="auto" src="/svg/car.svg"/>
                &nbsp;{{ distance_format(providers.distance) }} miles from you
              </div>
              <!-- <div class="provider_icon_text">
                <img class="mr-2" width="20px" height="auto" src="/svg/hospital.svg"/>
                &nbsp; {{ providers.category }}
              </div> -->
              <div class="provider_icon_text">
                <img class="mr-2" width="20px" height="auto" src="/svg/calendar_alt.svg"/>
                &nbsp; Next day appointments typically available.
              </div>
              <div class="provider_icon_text">
                <img class="mr-2" width="20px" height="auto" src="/svg/map_marker_smile.svg"/>
                &nbsp; {{ providers.homeCare == true ? 'In Home Care;':'At-Clinic;'}} Virtual; Text/Chat; Phone; At Work
              </div>
              <div class="provider_buttons">
                <button class="provider_button" v-if="!loggedIn" @click="goToRegister(providers.id, providers, 'location')">Select</button>
                <button class="provider_button" @click="goToDetails(providers, 'location')">View Details</button>
              </div>
            </div>
            </div>

          </div>

          <!-- <div>
            {{ clinician?.clinics[0].coordinates }}
          </div> -->

          <!-- MAP DESKTOP -->
          <div class="profile_header_div">
            <div class="profile_row_2 profile_map mt-0">
              <!-- <GmapMap :center="clinicianCenter" :zoom="18" class="profile_map_size"> -->
              <GmapMap :center="parseCoordinates(clinician?.clinics[0].coordinates)" :zoom="18" class="profile_map_size">
                <GmapMarker :position="clinicianCenter"> </GmapMarker>
                <GmapMarker 
                  v-for="(place, index) in clinician?.clinics" 
                  :key="index" 
                  :position="parseCoordinates(place.coordinates)" 
                  @click="openCard(place)" 
                  :icon="{ url: '/dark-blue.png', scaledSize: { width: 40, height: 60 } }"
                />
                <GmapInfoWindow :position="infoWindowPosition"  :opened="infoWindowOpened"  @closeclick="closeInfoWindow">
                  <v-card height="auto"  width="250" class="card-radius v_card_height no_box_shadow">
                      <div>{{  clinician?.clinics?.providerName }}</div>
                  </v-card>
                </GmapInfoWindow>
              </GmapMap>
            </div>
            <!-- <div class="profile_cursor display_flex display_flex_mid profile_color_primary mb-10 mt-2" @click="getDirections()">
              Get directions on Google Maps &nbsp; <img src="/svg/chevron_right.svg" class="ml-1" height="18"/>
            </div> -->
          </div>

        </div>

      </div>

      <!-- {{ clinician }} -->


    </div>

    <!-- OLD CONTENT -->
    <!-- <div class="row blue-underline mb-3">
      <div
        role="button"
        class="col-md-12 ml-4 mt-4 font-weight-bold text-gray d-flex"

      >
        <svg
      
          class="mr-2 mt-1"
          width="14"
          height="16"
          viewBox="0 0 14 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.6269 23.764L1.99634 12.9562C1.92725 12.8861 1.87267 12.803 1.83571 12.7118C1.79875 12.6205 1.78014 12.5229 1.78095 12.4245C1.78176 12.326 1.80197 12.2287 1.84042 12.1381C1.87888 12.0474 1.93482 11.9653 2.00505 11.8963L12.8118 1.26475"
            stroke="gray"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p  class="mb-0">Back</p>
      </div>
    </div>
    <div>
      <div class="row form-row-height d-flex pt-2 pb-2 light-blue-text">
        <div class="col-12 col-md-5 d-flex font-weight-bold">
          <div class="col-12 mt-1 pl-md-16">
              <v-card
              outlined color="transparent"
              height="fit-content"
              width="270px"
              class=" mt-2"
              :class="{ 'no-pointer': disablePointer }"
            >
              <div class="display pl-1 pr-1">
                <div class="text-start justify-start mb-1 mt-0">
                  <img
                    :src="clinician?.logo ? clinician?.logo :'/tpa/clinicians.svg'"
                    class="mt-0 mb-0"
                    height="60"
                  />
                </div>
                <div class="text-start mt-2 mb-0 text-ellipsis">
                  <p class="font-20 font-weight-500 mb-0">
                    {{ clinician?.providerName }}, {{clinician?.credentials }}
                  </p>
                </div>
                <div class="text-start font-weight-400 mb-0 text-ellipsis">
                  <p class="font-16">
                    {{ clinician?.category}}                    
                  </p>
                </div>
                <div class="justify-start d-flex mb-0">
                  <v-chip
                  class=""
                  color="#CDFFCC"
                  text-color="#333333"
                >
                Clinician
                </v-chip>
                </div>
              </div>
            </v-card>
          </div>

        </div>




        <div class="col-12 col-md-7">
          <v-expansion-panels  multiple class="col-12" v-model.trim="panel">
              <v-expansion-panel class="mb-3" >
              <v-expansion-panel-header>
                <div class="col-12 expansion-header pt-0 pb-0">
                  Details
                </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="col-12 pl-0 pt-0">
                  <div class="col-12 expansion-title pb-0">
                    Gender: 
                  </div>
                  <div class="col-12 expansion-content" v-if="clinician?.details.gender == 'F' || clinician?.details.gender == 'Female'"> 
                    Female
                  </div>
                  <div class="col-12 expansion-content" v-else-if="clinician?.details.gender == 'M' || clinician?.details.gender == 'Male'"> 
                      Male
                    </div>
                    <div class="col-12 expansion-content" v-else> 
                      /
                    </div>
                  <div class="col-12 expansion-title mt-2 pb-0"> 
                      NPI:
                    </div>
                    <div class="col-12 expansion-content"> 
                      {{clinician?.details.NPI ? clinician?.details.NPI : ''}}
                    </div> -->

                    <!-- <div class="col-12 font-weight-bold mt-2 pb-0"> 
                      License Number:
                    </div>
                    <div class="col-12 text-gray"> 
                      {{clinician?.details.license?.number}}
                    </div>
                    <div class="col-12 font-weight-bold mt-2 pb-0"> 
                      State:
                    </div>
                    <div class="col-12 text-gray"> 
                      {{clinician?.details.license?.state}}
                    </div>
                    <div class="col-12 font-weight-bold mt-2 pb-0"> 
                      Certification Date:
                    </div>
                    <div class="col-12 text-gray"> 
                      {{clinician?.details.license?.date}}
                    </div> -->
                
                <!-- </v-expansion-panel-content>
              </v-expansion-panel> -->
              <!-- <v-expansion-panel class="mb-3" >
                  <v-expansion-panel-header>
                    <div class="col-12 expansion-header pt-0 pb-0">
                      Contact Information
                    </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-divider horizontal class="mb-2"> </v-divider>
                      <div class="col-12 font-weight-bold mt-0 pb-0"> 
                          Address: 
                        </div>
                        <div class="col-12 text-gray mt-0"> 
                          {{ clinician?.contact?.address1 }}
                      </div> 
                      <div class="col-12 font-weight-bold mt-0 pb-0"> 
                        Phone Number: 
                      </div>
                      <div class="col-12 text-gray mt-0" > 
                        {{ clinician?.contact?.phone }}                      
                      </div>   
                      <div class="col-12 font-weight-bold mt-0 pb-0"> 
                        Email: 
                      </div>
                      <div class="col-12 text-gray mt-0"> 
                        {{ clinician?.contact?.email }}
                      </div>    
                    
                              
                      </v-expansion-panel-content>
                  </v-expansion-panel> -->
                  <!-- <v-expansion-panel class="mb-3" >
                      <v-expansion-panel-header>
                        <div class="col-12 expansion-header pt-0 pb-0">
                          Clinic or Organization
                        </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="col-12 pl-0 pt-0">
                          <div class="col-12 row d-flex">                        
                            <v-card
                            height="220px"
                            class=" mb-5 mt-1 mr-2 col-12 col-md-3"
                            v-for="(item,index1) in clinician?.clinics" :key="index1"
                            @click="goToDetails(item, 'provider')"
                          >
                          <div class="display pl-1 pr-1 pb-1">
                            <div
                            v-if="item.logo"
                            class="text-start justify-start mb-1 mt-0"
                            style="height: 60px; overflow: hidden; background-size: contain;margin:10px"
                            :style="`background-image:url(${item.logo})`"
                          ></div>
                          <div
                            v-else
                            class="text-start justify-start mb-1 mt-0"
                            style="height: 80px; overflow: hidden; background-size: contain;margin:10px;background-image:url('/tpa/clinicians.svg')"
                          ></div> -->
                            <!-- <div class="text-start justify-start mb-1 mt-0">
                              <img
                                :src="  item.logo ? item.logo : '/tpa/clinicians.svg'"
                                class="pointer mt-2 mb-0"
                                width="80"
                                  height="45"
                              />
                            </div> -->
                            <!-- <span class="text-start mt-0 font-weight-bold text-ellipsis-clinician">
                              <p class="font-14 mb-0">
                              {{item.companyName ? item.companyName : 'Provider'}}
                              </p>
                            </span>
                            <span class="text-start text-ellipsis-clinician">
                              <p class="font-14 mb-0"> {{item.category}}</p>
                            </span>
                            <div class="justify-start d-flex">
                              <v-chip
                              class=""
                              :color="item.type == 'Organization' || item.type == 'Clinic & Organization' ? '#FFF4BF' :'#CDFFCC'"
                              text-color="#333333"
                              small
                            >
                            {{ item.type }}
                            </v-chip>
                            </div>
                          </div>
                          </v-card> 
                          </div>
                        
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    
            </v-expansion-panels>
        </div>
      </div>
    </div> -->

    <!-- <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal> -->
  </div>
</template>
  
<script>
  // import successModal from "../../../TablesTemplate/SuccessDialog.vue";
  import api from "../../../../services/api";
  import { mapState } from 'vuex';
  import axios from 'axios'
  
  export default {
    // components: { successModal },
    data() {
      return {
        profileTab: [
          { tabName: "Info", tabID: "tab_info", status: "close" },
          { tabName: "Services", tabID: "tab_services", status: "close" },
          { tabName: "Locations", tabID: "tab_locations", status: "close" },
        ],
        userCenter: {
          lat: 0,
          lng: 0
        },
        clinicianCenter: { 
          lat: 0,
          lng: 0
        },
        infoWindowOpened: false,
        infoWindowPosition: null,
        infoWindowContent: {
          name: "",
          description: "",
        },
        descLength: false,
        disablePointer: true,
        clinician: null,
        panel: [1],
        destination: null,
        startPlace: null,
      };
    },
    created() {
      this.getProvider();
      this.reverseGeocodeLocation();
    },
    computed: {
      loggedIn() {
        return this.memberInfo && this.$cookies.get("token");
      },
      ...mapState({
        memberInfo: (state) => state.memberInfo
      }),
    },
    methods: {
      parseCoordinates(coordinates) {
        // console.log(coordinates);
        if(coordinates) {
          let newLat = parseFloat(coordinates.lat);
          let newLng = parseFloat(coordinates.lng);
          return { lat: newLat, lng: newLng };
        } else {
          return { lat: 0, lng: 0 };
        }
      },
      openCard(place){
        // console.log(place);
        if(place.location){
          this.infoWindowPosition = place.location;
        }else{
          this.infoWindowPosition = place;
        }

        this.infoWindowContent = place;
        if (place && place.item) {
          this.card = place.item;
        } else {
          this.card = null;
        }
        this.infoWindowOpened = true;
      },
      returnClinicanName(inputString){
      if(inputString){
        const words = inputString.split(' ');
        const capitalizedWords = words.map(word => {
          if (word.length === 0) return word;
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
          const capitalizedString = capitalizedWords.join(' ');
          return capitalizedString;
        }
      },  
      backToMarketplace() {
        this.$store.commit("setDependent", "");
        this.$router.push("/marketplace");
      },
      returnCapitalizedName(inputString){
        const words = inputString.split(' ');
        const capitalizedWords = words.map(word => {
          if (word.length === 0) return word;
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
        const capitalizedString = capitalizedWords.join(' ');
        return capitalizedString;
      },
      closeInfoWindow() {
        this.infoWindowOpened = false;
        this.selectedPlace = null; 
      },
      goToRegister(dpcID, card, entity) {
        if (this.userInfo && this.userInfo.nextStep == 'complete' && this.userInfo.providerId != null &&
            this.userInfo.locationId != null)
            {
              this.cannotEnrollModal = true;   
            }
            else{
        if(entity != 'location') {
          this.goToDetails(card, entity);
        }
        else {
          let params = "";
          if (card.locationId) {
            params = `?locationId=${card.locationId}`;
          }
          api().get(`/member/app/market/provider/${dpcID}` + params).then((res) => {
            if (res) {
              this.provider = res.data;
              this.$store.commit("setSelectedProvider", this.provider);
              this.$router.push("/register");
            }
          });
        }
        }
      },
      distance_format(distance) {
        if(distance) {
          return distance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
      },
      goToSection(tab) {
        for (let i = 0; i < this.profileTab.length; i++) {
          this.profileTab[i].status = 'close';
        }
        const indx = this.profileTab.findIndex(obj => obj.tabID === tab.tabID);
        if (indx !== -1) {
          this.profileTab[indx].status = "open";
        }
        document.getElementById(this.profileTab[indx].tabID).scrollIntoView({behavior: 'smooth', top: 0, inline: 'start'});
      },
      // getDirections() {
      //   let fromLat = this.$store.state.userLocation.lat;
      //   let fromLang = this.$store.state.userLocation.lng;
      //   let toLat = this.userCenter.lat;
      //   let toLang = this.userCenter.lng;
      //   window.open(`https://www.google.com/maps/dir/${fromLat},${fromLang}/${toLat},${toLang}`, '_blank');
      // },
      closeSuccessModal() {
        this.$parent.getSolutions();
        this.$router.go("-1");
      },
      getProvider() {
        // let latlng = {lng: this.$store.state.userLocation.lng, lat: this.$store.state.userLocation.lat}
        // let currentLocation = new URLSearchParams(latlng).toString()
        api()
          .get("/member/app/market/doctor/"+this.$route.params.id)
          .then((res) => {
            if (res) {
              this.clinician = res.data;
              this.geocodeLocation(res.data)
              this.clinician.logo = res.data?.logo ? res.data.logo : '';
            }
          });
      },
      favorite() {
      let data = {
        entity: 'provider',
        provider: this.$route.params.id,
        favorite: !this.clinician.favorite,
      };
      api()
        .post("/member/app/market/favorite", data)
        .then((res) => {
          if (res) {
            this.clinician.favorite = !this.clinician.favorite;
          }
        });
    },
    goToDetails(item, entity) {
      // locatioId is missing from item because it wasn't included in the clinic object
      if (entity == "solution") {
        this.$router.push(`/marketplace/solutions/details/${item.id}`);
      }
      else if (entity == "location") {
        this.$store.state.documentTitle = item.name;
        this.$router.push({
          path: `/marketplace/provider/details/${item.id}`,
          query: { location: item.locationId },
        });
      }
      else if (entity == "vendor") {
        this.$router.push(`/marketplace/vendor/details/${item.id}`);
      }
      else if (entity == "provider") {
        this.$router.push(`/marketplace/clinician/details/${item.id}`);
      }
      this.showOverlay = false;
    },
    geocodeLocation(item) {
       const address = `${item.contact.address1} ${item.contact.city}, ${item.contact.state} ${item.contact.zipCode}`
      this.destination = item.contact.address1 + ' '+ item.contact.city + ' '+ item.contact.state + ' '+ item.contact.zipCode;

      api()
            .post(`/member/app/market/locations/geocode`, { address })
            .then((res) => {
                if (res && res.data) {
                    const { lat, lng } = res.data.results[0].geometry.location;
                    item.latitude = lat;
                    item.longitude = lng;
                    const placeItem = { item, location: res.data.results[0].geometry.location };
                
                    this.clinicianCenter = res.data.results[0].geometry.location;
                    // console.log('this.userCenter ',this.userCenter )
                }
            })
            .catch((error) => {
                console.error('Geocoding error:', error);
            });
  },
    reverseGeocodeLocation(lat, lng) {
        lat = this.$store.state.userLocation.lat;
        lng = this.$store.state.userLocation.lng;

        api()
            .post(`/member/app/market/locations/geocode`, { lat, lng })
            .then((res) => {
                if (res && res.data) {
                    const address = res.data.results[0].formatted_address;
                    this.startPlace = address;
                    // console.log('Address:', address);
                } else {
                    console.error('Reverse geocoding failed', res);
                }
            })
            .catch((error) => {
                console.log('error', error.response);
                console.error('Reverse geocoding failed:', error.response?.data);
            });
    }
  },
};
  </script>
  <style scoped>
  .participate {
    width: 150px;
    color: white;
  }
  .next {
    width: 650px;
    color: white;
  }
  .okay {
    width: 250px;
    color: white;
  }
  .no-pointer {
    cursor: default;
  }
  .text-ellipsis{
    height: 58px;
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  }
  .text-ellipsis-clinician{
    height: 40px;
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  }
  @media only screen and (max-width: 600px) {
    .margin-on-mobile{
      margin-top: 45px;
    }
  }
  </style>
  