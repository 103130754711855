<template>
  <div>
    <!-- Fullscreen Loader -->
    <FullscreenLoader v-if="selectingProvider" />

    <div class="profile_main_content">
      <!-- HEADER -->
      <div class="profile_header_container" v-if="!loggedIn">
        <div class="profile_header_div display_flex display_flex_left">
          <img class="profile_logo" src="/apaly-logo.png" height="40px" width="auto">
        </div>
        <div class="profile_header_div display_flex display_flex_right">
          <v-btn @click="$router.push('/login')" color="primary" class="profile_hreader_btn">
            Login 
          </v-btn>
        </div>
      </div>

      <!-- Spacer for when user is NOT logged in -->
      <div class="profile_spacer" v-if="!loggedIn"></div>

      <!-- Spacer for when user is logged in -->
      <div class="profile_spacer_loggedIn" v-else></div>

      <!-- MAIN INFO -->
      <div class="profile_main_info"> 

        <div class="profile_header_div">
          <!-- BACK BUTTON -->
          <div class="profile_row profile_row_extra">
            <!-- BACK BUTTON -->
            <div class="profile_header_div">
              <div @click="backToMarketplace()" role="button" class="mt-2 display_flex display_flex_mid" >
                <img src="/svg/backArrow.svg" width="16" />
                <p class="ma-0 ml-2">Back</p>
              </div>
            </div>
            <!-- BADGE -->
            <div class="badge_n_share">
              <div class="profile_header_div display_flex display_flex_mid display_flex_right text_nowarp">
                Trusted Partner &ensp; <img src="/svg/apaly_badge.svg" width="40" height="auto" />
              </div>
              <div class="mt-2 mb-2">
                <button class="share_button" @click="shareProfile()">
                  <img :src="`/svg/${doneSharing == true ? 'icon_check.svg' : 'icon_share.svg'}`" width="20" height="auto"/>&nbsp; Share
                </button>
              </div>
              <div class="sharingDone" v-if="doneSharingNotice">
                Link copied. Ready to share!
              </div>
            </div>
          </div>

          <!-- PROFILE PICTURE AND NAME -->
          <div class="profile_card_header">
            <!-- LOGO -->
            <div class="marketplace_item_logo" :style="`background-image:url(${returnCardImage(provider)})`"></div>
            &emsp;
            <!-- DPC Name -->
            <div class="marketplace-item-content-name card_title">
                {{ returnCapitalizedName(provider?.companyName)  }} 
            </div>
          </div>

          <!-- DISTANCE -->
          <div class="profile_row display_flex_left">
            <div v-if="provider?.entity == 'location' && provider?.distance != null" class="distance_chip ml-8">
              <img class="ml-1 mr-2 card_icon" src="/svg/car.svg"/>
              &nbsp;{{ distance_format(provider?.distance) }} miles from you
            </div>
          </div>

          <!-- NEXT DAY APPOINTMENT -->
          <div class="profile_row display_flex_left" v-if="provider?.newPatients">
            <div class="display_flex display_mid">
              <img class="mr-2 card_icon" src="/svg/calendar_alt.svg" />
              <p class="ma-0 card_text"> Next day appointments typically available</p>
            </div>
          </div>

          <!-- IF NOT ACCEPTING NEW PATIENT -->
          <div class="profile_row display_flex_left" v-else-if="!provider?.newPatients">
            <div class="display_flex display_mid">
              <img class="mr-2 card_icon" src="/svg/info_circle.svg" />
              <p class="ma-0 card_text"> Not accepting new patients </p>
            </div>
          </div>

          <!-- PROFILE ICON TEXTS -->
          <div class="profile_row_block">
            <div class="profile_icon_text" v-for="careModality in careModalities(provider) ">
              <div class="display_flex display_flex_mid">
                <img class="mr-2 card_icon" :src="`/svg/${careModality.icon}`"/>
                &nbsp; {{ careModality.name }}
              </div>
            </div>
          </div>

          <!-- CONFIRMATION BUTTON -->
          <div class="profile_row" v-if="provider && provider?.newPatients && homeInfo &&  homeInfo?.subscription && !homeInfo?.subscription?.status == 'pending' && !loggedIn">
            <div class="profile_badge_container display_flex display_flex_mid" @click="goToRegister()">
                <img src="/svg/dollar_badge.svg" width="35" height="auto" class="mr-3"/>
                <p class="mb-0"> Select this clinic and then we'll confirm that your employer pays for this benefit</p>
                <img src="/svg/chevron_right.svg" width="35" height="auto" class="mr-3"/>
            </div>
          </div>

        </div>

        <!-- LEFT SIDE -->
        <!-- <div class="profile_header_div profile_mobile_hide" style="display: none">
        </div> -->

      </div>

      <!-- TABS -->
      <div class="profile_main_row">
        <br> <!-- SPACER -->
        <div class="profile_row">
          <div class="profile_tab_con display_flex display_flex_mid">
            <div class="profile_tab_div text_nowarp" v-for="(tab, t) in profileTab" :key="'tab'+t">
              <div :class="`profile_tab ${tab?.status == 'open'? 'profile_tab_active':''}`" @click="goToSection(tab)">
                {{ tab.tabName }}
              </div>
            </div>
          </div>
        </div>
        <div class="profile_line"></div>  <!-- LINE -->
        <br> <!-- SPACER -->
      </div>

      <!-- DECRIPTION -->
      <div class="profile_main_row mb-10">
        <div class="profile_info_title mb-2">Description:</div>

        <!-- DISPLAYING LESS -->
        <div v-if="descLength" class="profile_info_text profile_desc_container" :style="descLength ? 'height:auto' : ''">
            {{ provider?.details?.description.substring(0,200) }}...
            <br><br>
            <small class="profile_display_btn" @click="descLength = false">
              show more <v-icon small color="primary">mdi-chevron-down</v-icon>
            </small>
        </div>

        <!-- DISPLAYING MORE -->
        <div v-else-if="!descLength && provider?.details?.description!=null && provider?.details?.description.length > 300 " class="profile_info_text profile_desc_container" style="height:auto">
            {{ provider.details?.description }}
            <br><br>
            <small class="profile_display_btn">
              show less <v-icon small color="primary">mdi-chevron-up</v-icon>
            </small>
          </div>

        <!-- NORMAL DISPLAY -->
        <div v-else class="profile_info_text profile_desc_container" style="height:auto">
          {{ provider?.details && provider?.details.description ? provider?.details.description : 'No Description' }}
        </div>
      </div>

      <!--CONTACT INFO AND MAP -->
      <div id="tab_contacts" class="profile_main_info mb-10">
        <!-- DECRIPTION -->
        <div class="profile_header_div">
          <div class="profile_info_title mb-2">Contact Information</div>

          <!-- WEBSITE --> 
          <div class="profile_icon_text profile_cursor">
            <div class="display_flex display_flex_mid profile_color_primary mb-2" @click="openWebsite(provider?.details?.website)">
              Visit website &nbsp; <img src="/svg/visitWeb.svg" class="ml-1" height="18"/>
            </div>
          </div>

          <!-- PHOME NUMBER -->
          <div class="profile_row_2">
            <div class="profile_info_subtitle mb-1">Phone Number:</div>
            <div class="profile_info_text" style="height:auto">
              {{ provider?.contact?.phone }}
            </div>
          </div>

          <!-- ADDRESS -->
          <div class="profile_row_2">
            <div class="profile_info_subtitle mb-1">Address:</div>
            <div class="profile_info_text" style="height:auto">
              {{ provider?.contact?.address1 }}&nbsp;{{ provider?.contact?.city }},&nbsp;{{ provider?.contact?.state }}&nbsp;{{ provider?.contact?.zipCode }}
            </div>
          </div>
          
          <!-- DISTANCE -->
          <div class="profile_row_2" v-if="provider?.entity == 'location' && provider?.distance != null">
            <div class="profile_info_subtitle mb-1">{{ distance_format(provider.distance) }} miles from you</div>
          </div>
        </div>

        <!-- MAP & CONTACTS -->
        <div class="profile_header_div">

          <!-- MAP -->
          <div class="profile_row_2 profile_map mt-0">
            <GmapMap :center="userCenter" :zoom="18" class="profile_map_size">
              <!-- User's current location -->
              <!-- <GmapMarker :position="{ lat: parseFloat(125.612), lng: parseFloat(7.0731) }"  @click="openCard(userCenter)"></GmapMarker> -->

              <!-- DirectionsRenderer for displaying the route -->
              <!-- <GmapDirectionsRenderer :origin="{ currentLocation }" :destination="{ userCenter }" :travel-mode="'TRANSIT'" suppressMarkers: true></GmapDirectionsRenderer> -->
              <!-- <GmapDirectionsRenderer :options="directionsRendererOptions" /> -->

              <!-- <component
                :is="$options.components.GmapDirectionsRenderer || 'div'"
                :origin="{ userCenter }"
                :destination="{userCenter}"
                :travel-mode="'TRANSIT'"
              /> -->

              <!-- DistanceMatrix for calculating the distance -->
              <!-- <GmapDistanceMatrix
                :origins="[{ lat: parseFloat(125.612), lng: parseFloat(7.0731) }]"
                :destinations="[userCenter]"
                @result="handleDistanceMatrixResult"
              /> -->

              <!-- Desired location -->
              <GmapMarker 
                :position="userCenter"
                @click="openCard(userCenter)"
                :icon="{ url: '/dark-blue.png', scaledSize: { width: 40, height: 60 } }"
              >
              </GmapMarker>

              <GmapInfoWindow :position="infoWindowPosition"  :opened="infoWindowOpened"  @closeclick="closeInfoWindow">
                <!-- <v-card class="profile_map_window">
                    <div class="profile_map_card">{{ returnCapitalizedName(provider.companyName) }}</div>
                </v-card> -->
                <div class="card_layout_map" :style="resolveMarketplaceBackgroundColor(provider)">
                  <!-- CARD -->
                  <v-card v-if="provider" height="auto"  width="250" class="card-radius v_card_height no_box_shadow">

                    <!-- <div class="marketplace-item tester"> -->
                    <div class="no_box_shadow">

                      <!-- CARD HEADER -->
                      <div class="market_card_header_map">

                        <!-- LOGO -->
                        <div class="card_logo_map" :style="`background-image:url(${returnCardImage(provider)})`"></div>

                        &emsp;

                        <!-- DPC Name -->
                        <!-- <div class="marketplace-item-content-name" :class="!loggedIn ? 'pt-8' : 'pt-4'"> -->
                        <div class="marketplace-item-content-name card_title_map">
                            {{ returnCapitalizedName(provider?.companyName) }}
                            <span v-if="provider?.credentials">, {{ provider?.credentials }}</span>
                        </div>

                      </div>

                      <div class="card_info_container_map">
                        <div class="card_info_icontexts_map">
                          <!-- ADDRESS -->
                          <div v-if="provider?.entity == 'location'" class="marketplace-item-content-address pt-4 card_icon_text">
                            <img class="mr-2 card_icon_map" src="/svg/cardLocation.svg" />
                            <p class="ma-0 card_text_map">
                              {{ provider?.address }}&nbsp;{{ provider?.city }},&nbsp;{{ provider?.state }}&nbsp;{{ provider?.zipCode }}
                            </p>
                          </div>

                          <!-- DISTANCE CHIP -->
                          <!-- <div  class="distance_chip ml-8">
                            <img class="ml-1 mr-2 card_icon" src="/svg/car.svg"/>
                            &nbsp;{{ distance_format(card.distance) }} miles from you
                          </div> -->

                          <!-- CHIP" Covered/Uncovered -->
                          <!-- <div v-if="card.covered" class="distance_chip ml-8">
                            {{  card.covered ? 'Covered' : 'Uncovered' }}&nbsp;
                            <img class="ml-1 mr-2 card_icon" :src="`/svg/${card.covered ? 'coveredIcon.svg' : 'uncoveredIcon.svg'}`"/>
                          </div> -->

                          <div v-show="provider?.entity == 'location'">

                            <!-- APPOINTMENT -->
                            <div class="marketplace-item-content-category pt-4 card_icon_text">
                              <img class="mr-2 card_icon_map" src="/svg/calendar_alt.svg" />
                              <p class="ma-0 card_text card_text_map"> Next day appointments typically available.</p>
                            </div>

                            <!-- PROFILE EXTRA INFO -->
                            <div class="marketplace-item-content-category pt-4 card_icon_text">
                              <img class="mr-2 card_icon_map" src="/svg/map_marker_smile.svg" />

                              <!-- SEE IF card.name HAS "NICE" -->
                              <!-- <p class="ma-0 card_text card_text_map">{{ String(returnClinicanName(provider.name)).includes("Nice") ? 'In Home Care;':'At-Clinic;'}} Virtual; Text/Chat; Phone; At Work</p> -->
                              <p class="ma-0 card_text card_text_map">{{ provider?.homeCare == true ? 'In Home Care;':'At-Clinic;'}} Virtual; Text/Chat; Phone; At Work</p>
                            </div>
                            
                          </div>

                        </div>

                      </div>

                    </div>

                  </v-card>
                </div>
              </GmapInfoWindow>

            </GmapMap>
          </div>
          <div class="profile_cursor display_flex display_flex_mid profile_color_primary mb-2 mt-2" @click="getDirections()">
            Get directions on Google Maps &nbsp; <img src="/svg/chevron_right.svg" class="ml-1" height="18"/>
          </div>

          <!-- {{ provider }} -->

        </div>

      
      </div>

      <!-- SERCVICES -->
      <div id="tab_services" class="profile_main_row mb-10">
        <div class="profile_info_title mb-2">Services</div>
        <div class="profile_category_container">

          <!-- CORE SERVICES OFFERED -->
          <div class="profile_cont_div">
            <div class="profile_icon_text_2">
              <img src="/svg/heart_circle.svg" width="40" height="auto" /> &nbsp; Core Services Offered
            </div>
            <div class="mt-2">
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Wellness Checks and Preventative Care</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Chronic Disease Management</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">24/7 Access to Manage your Care </p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Sick Care </p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Mental Health Guidance </p>
              </div>
            </div>
          </div>

          <!-- ADDITIONAL COVERED SERVICES -->
          <div class="profile_cont_div" style="display: none;">
            <div class="profile_icon_text_2">
              <img src="/svg/heart_circle.svg" width="40" height="auto" /> &nbsp; Additional Covered Services
            </div>
            <div class="mt-2">
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Nutritionists</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Pharmacy</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Flu Shots and Select Vaccines</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Pediatrics</p>
              </div>
            </div>
          </div>

          <!-- OTHER SERVICES AVAILABLE -->
          <div class="profile_cont_div" style="display: none;">
            <div class="profile_icon_text_2">
              <img src="/svg/heart_circle.svg" width="40" height="auto" /> &nbsp; Other Services Available  
            </div>
            <div class="mt-2">
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Imaging</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Physical Therapy</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Cardiac testing</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Pulmonary Studies</p>
              </div>
              <div class="display_flex display_flex_mid">
                <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                <p class="mb-0">Respiratory Therapy</p>
              </div>
            </div>
          </div>

          

        </div>
      </div>

      <!-- CARE MODALITY -->
      <div class="profile_main_row mb-10">
        <div class="profile_info_title mb-2">Care Modality</div>

        <!-- CORE SERVICES OFFERED -->
        <div class="profile_category_container">

          <!-- VIRTUAL -->
          <div class="profile_cont_div" v-for="careModality in careModalities(provider)">
            <div class="profile_icon_text_3">
              <img class="mr-2 profile_modality_icon" :src="`/svg/${careModality.icon}`"/>
              <div class="profile_modality_content">
                    {{ careModality.name }}
                <br>
                <div class="profile_modality_text">
                    {{ careModality.details }}
                </div>
              </div>
            </div>  
          </div>


        </div>
      </div>

      <!-- PROVIDERS -->
      <div id="tab_providers" :class="`profile_providers_layout ${provider && !provider?.newPatients ? 'extra_margin_joinwaitlist':'extra_margin_newpatient'}`" >

        <div class="profile_info_title mb-2">Providers</div>

        <div class="" v-if="provider?.clinicians?.length <= 0"> 
          Clinic <strong>{{ returnCapitalizedName(provider?.companyName) }}</strong> has not listed their providers yet
        </div>

        <!-- SAMPLE PROVIDER -->
        <div style=" ">

        <div class="profile_provider_card_layout" v-for="(clinician, c) in provider?.clinicians" :key="'c'+c">
          <div class="profile_provider_card_header">
            <div class="profile_provider_card_image" 
              :style="`background-image:url(${clinician?.logo ? clinician.logo : clinician?.gender == 'F' ? '/clinician_f.png':'/clinician_m.png'})`">
            </div>
            &emsp;
            <div class="profile_provider_card_name">
                {{ clinician?.providerName }}
            </div>
          </div>
          <div class="provider_icon_text">
            <img class="mr-2" width="20px" height="auto" src="/svg/hospital.svg"/>
            &nbsp; {{ clinician?.category }}
          </div>
          <div class="provider_icon_text">
            <img class="mr-2" width="20px" height="auto" src="/svg/femaleClinician.svg"/>
            &nbsp; {{ clinician?.gender == 'F' ? 'Female':'Male' }}
          </div>
          <div class="profile_icon_text_3">
            <div class="provider_title">
              Works At
            </div>
            <div class="provider_texts">
              <div class="profile_modality_text">
                {{ clinician?.worksAt }}
              </div>
            </div>
          </div> 
          <div class="provider_buttons">
            <button class="provider_button" style="visibility: hidden;">Select</button>
            <button class="provider_button" @click="goToDetails(clinician, 'provider')">View Details</button>
          </div>
        </div>
        </div>

      </div>

      <!-- {{ provider.clinicians.length }} -->
      <!-- {{ provider.clinicians }} -->

    </div>

    

    <!-- FOOTER -->
    <div :class="`profile_footer ${loggedIn ? 'profile_footer_logged_in':''}`">

      <!-- SUBSCRIPTION STATUS FOR SUBSCRIBERS -->
      <!-- <button class="card_buttons card_button_disabled" v-if="memberInfo?.subscriberRelation == 'Self' && memberInfo?.providerId == card?.id && memberInfo?.locationId == card.locationId && memberInfo?.setup != 'incomplete'" disabled>
        {{ memberInfo?.subscription?.status == "active" ? "Active" : "Pending Membership" }}
      </button> -->

        <!-- Confirming Eligibility -->
        <v-btn v-if="currentMemberInfo?.nextStep == 'confirmPlan' && provider?.newPatients && currentMemberInfo?.locationId == provider?.locationId"  @click="goToRegister()" style="white-space:nowrap;">
            Confirm eligibility
        </v-btn>

        <!-- Pending membership -->
        <v-btn v-else-if="provider?.locationId === currentMemberInfo?.location?.id  && currentMemberInfo.subscription?.status === 'pending'" color="primary" class="no_box_shadow ml-2 mr-2"  >
            Pending Membership
        </v-btn>

        <v-btn v-else-if="provider?.locationId === currentMemberInfo?.location?.id  && memberInfo.subscription?.status === 'active'" color="primary" class="no_box_shadow ml-2 mr-2"  >
            Active Membership
        </v-btn>

            <!-- Select Provider -->
        <v-btn v-else-if="provider?.newPatients" color="primary" class="no_box_shadow ml-2 mr-2" @click="selectLocation()" >
            Select provider
        </v-btn>

        <!-- NOT ACCEPTING NEW PATIENTS | JOIN WAITLIST  -->
        <div class="profile_waitlist" v-else-if="!provider?.newPatients">

        <!-- Notice -->
        <div class="profile_notice">This location is not accepting new patients at the moment</div>

        <!-- Find another provider -->
        <v-btn color="transparent" class="no_box_shadow profile_waitlist_btn_1" style="border: 1px solid #0069F3 !important; color: #0069F3 !important" @click="goToMarketplace()">
            Find another location
        </v-btn>

        <!-- Join Waitlist  -->
        <!-- Subscriber/User can join waitlist if clinic is not accepting new patients -->
        <v-btn color="transparent" class="no_box_shadow profile_waitlist_btn_2" style="border: 1px solid transparent !important; color: #0069F3 !important; text-decoration: underline" @click="joinWaitlist(provider?.locationId)">
            Join the waitlist for this location
        </v-btn>
      </div>
    </div>

    <!-- DIALOGS -->

      <!-- Enter email to get notified -->
      <v-dialog v-model="notifyMe" width="500px" persistent>

        <v-card class="dialog_layout">
          
          <v-card-title class="d-flex justify-end">
            <v-icon role="button" @click="notifyMe = false" v-text="'mdi-close'" slot="end"></v-icon>
          </v-card-title>

          <v-card-text class="dialog_content">
            <div class="dialog_title dialog_primary" style="color: #333333">
              This location is not accepting new patients at the moment
            </div>
            <div class="dialog_subtext dialog_second">
              Enter your e-mail and we'll notify you as soon as this clinic is accepting new patients again
            </div>
            <div class="dialog_input">
              <div v-if="!loggedIn" style="font-size: 18px" class="mt-5 mb-3">Email</div>
              <input v-if="!loggedIn" placeholder="Enter email" dense type="email" style="width:100%" class="marketpalce_notice_input" v-model="unregisteredEmail"/>
              <v-checkbox
                class="dialog_checkbox mb-5"
                hide-details
                v-model="allowApalyToContact"
                label="I agree to be contacted by Apaly"
                :true-value="true"
                :false-value="false"
              >
              </v-checkbox>
            </div>
            <div class="dialog_buttons">
              <button class="dialog_single_button dialog_btn_primary" :disabled="!allowApalyToContact || unregisteredEmail == ''" @click="saveEmailAndNotifyMe()" >
                Notify me
              </button>
            </div>
          </v-card-text>

        </v-card>

      </v-dialog>

      <!-- Successfully added to waitlist -->
      <v-dialog v-model="addedToWaitlist" width="500px" persistent>
        <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="addedToWaitlist = false"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="text-center mb-5">
            <img src="/svg/email_sent.svg" />
          </div>
          <div class="dialog_title dialog_primary">You've been added to the waitlist</div>
          <div class="dialog_subtext dialog_second">
            We'll notify you as soon as this clinic is available and accepting new patients.
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary mt-5" @click="goToMarketplace()" >
              Find a different provider
            </button>
          </div>
        </v-card-text>
      </v-card>
      </v-dialog>

    <v-dialog v-model="chooseProvider" width="400px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="closeEnrollmentModal()"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>
        <v-card-text class="justify-start text-start">
          <div class="font-24 font-weight-400 text-start mb-3" style="color: #014d00">Eligibility Confirmed </div>
            <div class="font-16 font-weight-400 text-start " style="color: #333333">A representative from the clinic will contact you shortly to schedule your first appointment.  </div>
  
          <v-card-actions class="d-flex justify-center align-center pb-5">
            <v-btn @click="gotIt()" color="#0069F3" style="color: white" block
              >Okay</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="informativeModal" width="450px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="informativeModal = false"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <div class="font-24 font-weight-400 mb-3" style="color:#014D00;">Eligibility Confirmed</div>

<div class="font-16 font-weight-400" style="color: #333333">Would you like to add family members? </div>

          <v-card-actions
            class="d-flex d-block justify-center align-center pb-5 pt-5"
          >
            <v-btn
              @click="$router.push('/marketplace')"
              color="#0069F3"
              class="mr-5"
              outlined
              style="width: 100px"
              >No, I'm all set</v-btn
            >
            <v-btn
              @click="openNewMemberModal()"
              color="#0069F3"
              style="color: white; width: 200px"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- You have an pending/active membership -->
    <v-dialog v-model="cannotEnrollModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="cannotEnrollModal = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
            You have a {{ memberInfo?.subscription?.status }} membership with {{ memberInfo?.location?.name }}
          </div>
          <div class="dialog_subtext dialog_second">
            If you'd like to change your APC clinic, please contact support at
            <strong>support@apaly.com</strong>
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="cannotEnrollModal = false">
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

        <!-- Change provider -->
        <v-dialog v-model="changeProviderModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="changeProviderModal = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_nb">
            {{ selectedLocationToChange?.title }}
            <span class="dialog_blue">
                {{ selectedLocationToChange?.oldLocation }}
            </span>
          </div>
          <div class="dialog_subtext dialog_second mt-3">
            Are you sure you want to change your
             <span v-if="selectedLocationToChange?.isDependent"> dependent's</span>
            provider to <br>
            <span class="dialog_orange">{{ selectedLocationToChange?.name}}</span>
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="changeProviderModal = false">
              Cancel
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="updateProvider()">
              Yes
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Updated provider -->
    <v-dialog v-model="changedProviderModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="changedProviderModal = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Success..! You’ve updated your <span v-if="selectedLocationToChange?.isDependent">dependent's</span> provider.
          </div>
          <div class="dialog_subtext dialog_second mt-3">
            You<span v-if="selectedLocationToChange?.isDependent">r dependent</span>
             now have a pending membership with <br>
            <span class="dialog_orange">{{ selectedLocationToChange?.name }}</span>
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="changedProviderModal = false">
              Got It
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="pediatricMesage" width="350px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="backToMarketplace()" slot="end"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="justify-start text-start">
          <div class="font-24 font-weight-400 mb-3 pa-2" style="color: #990101">
            This clinic does not accept pediatrics patients of this age
          </div>
          <div
            class="font-16 font-weight-400 justify-start pa-2 pt-0"
            style="color: #333333"
          >
            Please find another DPC clinic for this dependent
          </div>
          <v-card-actions class="justify-center align-center pb-5">
            <v-btn
              @click="selectAnotherClinic()"
              color="#0069F3"
              block
              style="color: white"
              >Select another clinic</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dependentPediatricMesage" width="350px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="backToMarketplace()" slot="end"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="justify-center text-start">
          <div class="font-24 font-weight-400 mb-3 pa-2" style="color: #990101">
            This clinic does not offer pediatric services
          </div>
          <div
            class="font-16 font-weight-400 justify-start pa-2 pt-0"
            style="color: #333333"
          >
            Please find another DPC clinic for this dependent
          </div>
          <v-card-actions class="d-flex justify-center align-center pb-5">
            <v-btn
              @click="selectAnotherClinic()"
              color="#0069F3"
              block
              style="color: white"
              >Select another clinic</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="dependentEnrolled" width="350px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="finishedEnrollment()"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-start">
          <div class="font-24 font-weight-400 mb-3" style="color: #014d00">
            Dependent Enrolled Successfully
          </div>
          <div
            class="font-16 text-start font-weight-400"
            style="color: #333333"
          >
          You will soon get follow up outreach directly from your selected healthcare organization to schedule your first appointment. We have also emailed you their contact information. 

          </div>
          <v-card-actions
            class="d-flex justify-space-between align-center pb-5 pt-5 pa-0"
          >
            <v-btn
              @click="finishedEnrollment()"
              color="#0069F3"
              class=""
              outlined
              >Okay</v-btn
            >
            <v-btn
              @click="addDependentAfterEnroll()"
              color="#0069F3"
              class="text-white ml-0"
              style="color: white"
              >Add another dependent</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="inactiveEmployer" width="300px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="inactiveEmployer = false"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-start">
          <div class="font-24 font-weight-400 mb-3" style="color: #014d00">
            Oops, your employer does not offer this benefit yet
          </div>
         
          <v-card-actions class="d-flex justify-center align-center pb-5">
            <v-btn @click="inactiveEmployer = false"   color="#0069F3" width="170"
            class="mt-2 mb-2 text--white select-text"
              >Got It</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- SELECTING PROVIDER LOADER -->
    <!-- <v-dialog v-model="selectingProvider" width="500px" persistent>

        <v-card class="dialog_layout">

          <v-card-text class="dialog_content">
            <div class="dialog_image">
              <v-progress-circular indeterminate :size="50" color="primary" class="text-center mt-10 mb-5"></v-progress-circular>
            </div>
            <div class="dialog_title dialog_second text-center">
              Selecting Provider, please wait...
            </div>
          </v-card-text>

        </v-card>

    </v-dialog> -->

    <!-- Dependent error modal -->
    <v-dialog v-model="dependnetErrorModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="dependnetErrorModal = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error text-center">
            <!-- Employer's plan is not covered by this provider. -->
            {{errorMessage}}
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="dependnetErrorModal = false" >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dependent WITHOUT Custom Link -->
    <v-dialog v-model="dependentAllSet" width="500px" persistent>
        <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="refresh()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Family member enrolled
          </div>
          <div class="dialog_subtext dialog_second">
            <strong>{{`${selectedMember?.firstName} ${selectedMember?.lastName}`}}</strong> has access to their own personal provider. 
            <br><br>
            The clinic will reach out to schedule their first appointment.  
            <br><br>
            Would you like to add another family member? 
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="goToMembershipCard()">
              I'm all set
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="addDependentAfterEnroll()" >
              Add another 
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dependent WITH Custom Link -->
    <v-dialog v-model="dependentCustomLink" width="500px" persistent>
        <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="refresh()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Family member enrolled
          </div>
          <div class="dialog_subtext dialog_second">
            <strong>{{`${selectedMember?.firstName} ${selectedMember?.lastName}`}}</strong> has access to {{`${selectedMember?.gender == 'f' ? 'her':'his'}`}} own personal provider!
            <br><br>
            Now let's connect you to <strong>{{ selectedProvider?.companyName }}</strong> member portal, so you can schedule your family member's first appointment
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="goToCustomRedirect(postMemberEnrollment?.redirect)" >
              Connect me now
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Enrolled to provider -->
    <v-dialog v-model="subscriberAllSetModal" width="500px" persistent>
    <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
      </v-card-title>
      <v-card-text class="dialog_content">
        <div class="dialog_title dialog_primary">
          Success! You're enrolled with <strong>{{ selectedProvider?.companyName }}</strong>
        </div>
        <div class="dialog_subtext dialog_second">
          You now have access to your own personal provider!
          <br><br>
          The clinic will reach out to schedule your first appointment.  
        </div>
        <div class="dialog_buttons">
          <button class="dialog_single_button dialog_btn_primary" @click="goToMembershipCard()" >
            Okay
          </button>
        </div>
      </v-card-text>
    </v-card>
    </v-dialog>

    <!-- Subcriber's Modal:  Custom clinic redirect modal -->
    <v-dialog v-model="ifCustomModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Success! You're enrolled with <strong>{{ selectedProvider?.companyName }}</strong> 
          </div>
          <div class="dialog_subtext dialog_second">
            <!-- {{ postMemberEnrollment !== null ? postMemberEnrollment.message : '' }} -->
            You now have access to your own personal provider!
            <br><br>
            Now let's connect you to <strong>{{ selectedProvider?.companyName }}</strong> member portal, so you can create your patient account and schedule your first appointment 
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="goToCustomRedirect(postMemberEnrollment?.redirect)" >
              Connect me now
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- NO EMAIL -->
    <v-dialog v-model="noEmail" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="noEmail = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error text-center">
            {{ `${memberInfo?.firstName} ${memberInfo?.lastName} has no email` }}
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="noEmail = false" >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <DependentFlow
      v-if="dependentModal"
      @closeDependentModal="dependentModal = false"
      :dependentModal="dependentModal"
    />

  </div>
</template>

<script>
var _paq = window._paq || [];
_paq.push(["trackPageView"]);
import api from "../../../../services/api";
import DependentFlow from "../../../Common/DependentFlow.vue";
import { mapState } from 'vuex';
import FullscreenLoader from '../../../Common/FullscreenLoader.vue';
import Mixpanel from "mixpanel-browser";

export default {
  components: {
    DependentFlow,
    FullscreenLoader
  },
  props: {},
  data() {
    return {
      noEmail: false,
      selectingProvider: false,
      dependnetErrorModal: false,
      subscriberAllSetModal: false,
      errorMessage: "",
      postMemberEnrollment: null,
      somethingWrong: false,
      ifCustomModal: false,
      dependentCustomLink: false,
      dependentAllSet: false,
      selectedProvider: [],
      selectedMember: [],
      userInfo: [],
      notifyMe: false,
      addedToWaitlist: false,
      allowApalyToContact: false,
      selectedLocationForWaitlist: "",
      unregisteredEmail: "",
      doneSharing: false,
      doneSharingNotice: false,
      profileTab: [
        { tabName: "Info", tabID: "tab_info", status: "close" },
        { tabName: "Contact Information", tabID: "tab_contacts", status: "close" },
        { tabName: "Services", tabID: "tab_services", status: "close" },
        { tabName: "Providers", tabID: "tab_providers", status: "close" },
      ],
      inactiveEmployer: false,
      provider: null,
      dependentId: "",
      hasPediatric: false,
      tab: "info",
      dependentEnrolled: false,
      dependentPediatricMesage: false,
      dependentModal: false,
      dependentHasPediatric: false,
      pediatricMesage: false,
      hasFamily: true,
      informativeModal: false,
      chooseProvider: false,
      memberErrorModal: false,
      memberSuccessModal: false,
      slectedMessage: "",
      cannotEnrollModal: false,
      plans: [],
      descLength: false,
      userLat: null,
      userLong: null,
      center: {
        lat: 0,
        lng: 0
      },
      userCenter: {
        lat: 0,
        lng: 0
      },
      places: [],
      infoWindowOpened: false,
    infoWindowPosition: null,
    infoWindowContent: {
      name: "",
      description: "",
    },
    selectedPlace: null,
    centerData: {
        name: "Struga",
        description: "your address ",
      },
      card: null,
      homeInfo: null,
    // directionsRendererOptions: {
    //   suppressMarkers: true
    // },
    changeProviderModal: false,
    selectedLocationToChange: null,
    changedProviderModal: false,
    familyDetails: null,
    dependentProvider: null,
  };
  },
  mounted() {
    if (typeof google === 'undefined') {
    const timer = setInterval(() => {
      if (typeof google !== 'undefined') {
        clearInterval(timer); 
        this.setUpGoogleMaps(); 
      }
    }, 100);
  } else {
    this.setUpGoogleMaps();
  }

  // Google Directions
  // The problem with this is, the GmapDirectionsRenderer component isn't being rendered properly
  // even after setting installComponents: true, in the main.js

  // const directionsService = new window.google.maps.DirectionsService();
  // const directionsRenderer = new window.google.maps.DirectionsRenderer(this.directionsRendererOptions);

  // const request = {
  //   origin: this.currentLocation,
  //   destination: this.userCenter,
  //   travelMode: 'FLIGHT',
  // };
  
  // directionsService.route(request, (response, status) => {
  //   if (status === 'OK') {
  //     directionsRenderer.setDirections(response);
  //   }
  // });
},
  async created() {
    this.selectingProvider = true
    await this.getMember();

    // if (this.$store.getters.getEndrollmentStatus == true) {
    //   this.chooseProvider = true;
    // }

    if (this.$store.getters.getDependentId) {
      this.dependentId = this.$store.getters.getDependentId;
    }
    this.getProvider();
    if (this.memberInfo && this.$cookies.get("token")) {
      this.getPlans();
      this.getHomeInformation();
      // this.$matomo.trackPageView({
      //   customTitle: `Member ${this.provider.companyName}`, // Optional
      // });
    }

  },
  computed: {
    ...mapState({
      memberInfo: (state) => state.memberInfo,
      dependentInfo: (state) => state.dependentInfo,
    }),
    loggedIn() {
      return this.memberInfo && this.$cookies.get("token");
    },
    currentMemberInfo() {
        if (this.dependentInfo && this.dependentInfo.nextStep) {
            return this.dependentInfo;
        }
        return this.memberInfo;
    }
  },
  methods: {
    careModalities(location) {
        let careModalitiesValues = [
            {
                name: 'At-clinic',
                icon: 'hospital.svg',
                enabled: true,
                field: 'atClinic',
                details: 'Visit your provider face-to-face at the clinic',
            },
            {
                name: 'Phone',
                icon: 'telephone.svg',
                enabled: true,
                field: 'phone',
                details: 'Talk on the phone',
            },
            {
                name: 'Virtual',
                icon: 'laptop.svg',
                enabled: true,
                field: 'virtual',
                details: 'Meet through a video visit',
            },
            {
                name: 'At-work',
                icon: 'briefcase.svg',
                enabled: true,
                field: 'atWork',
                details: "See your provider at work (when they're there)",
            },
            {
                name: 'Text/Chat',
                icon: 'chat.svg',
                enabled: true,
                field: 'textChat',
                details: 'Chat/Text with your provider',
            },
            {
                name: 'In-home',
                icon: 'home_heart.svg',
                enabled: false,
                field: 'inHome',
                details: 'We come and see you at home',
            },
        ]

        if (location && location.careModality) {
            for (const key in location.careModality) {
                if (location.careModality.hasOwnProperty(key)) {
                    const isEnabled = location.careModality[key];
                    console.log('isEnabled', isEnabled);
                    const index = careModalitiesValues.findIndex(careModality => careModality.field === key)

                    careModalitiesValues[index].enabled = !!isEnabled;
                }
            }

        } else if (location && location.isVirtualLocation) {
            careModalitiesValues[0].enabled = false;
            careModalitiesValues[5].enabled = true;
        }

        return careModalitiesValues.filter(careModality => careModality.enabled)
    },
    async getDependentInfo(dependentId) {
        const depId = dependentId || this.$store.getters.getDependentId;
        if (depId && depId !== this.memberInfo.id) {
            this.$store.dispatch("getDependentInfo", depId);
        } else if (this.memberInfo) {
            if (this.memberInfo.id && this.memberInfo.subscriberRelation === 'Self') {
                this.$store.commit("setDependent", this.memberInfo.id);
            }
        }
    },
    goToMembershipCard() {
      this.$router.push('/membership').then(() => {
        window.location.reload();
      });
    },
    async shareProfile() {
      if (navigator.share || navigator.canShare) {
        // Mobile Sharing
        try {
          await navigator.share({
            title: 'Share' + this.returnClinicanName ? this.returnClinicanName : 'Clinic Profile',
            text: 'Check out this clinic profile!',
            url: window.location.href,
          });
          // console.log('Successfully shared');

          // Change icon to check
          this.doneSharing = true;
          this.doneSharingNotice = true;

          setTimeout(() => {
            this.doneSharingNotice = false;
          }, 3000);

        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        // Desktop Sharing
        // Copy Link to clipboard
        const tempInput = document.createElement('input');
        tempInput.value = window.location.href;
        document.body.appendChild(tempInput);

        tempInput.select();
        tempInput.setSelectionRange(0, 99999);

        try {
          document.execCommand('copy'); // execCommand still works
          // console.log('URL copied to clipboard (fallback):', tempInput.value);

          // Change icon to check
          this.doneSharing = true;
          this.doneSharingNotice = true;

          setTimeout(() => {
            this.doneSharingNotice = false;
          }, 3000);
          
        } catch (err) {
          console.error('Error copying to clipboard (fallback):', err);
        } finally {
          document.body.removeChild(tempInput);
        }
      }
    },
    saveWaitlist(email) {
        // If this.$store.getters.getDependentId has ID (Dependent ID), then use it. Else, use the memberInfo.id (Subscriber's ID)
      const memberID = this.$store.getters.getDependentId ? this.$store.getters.getDependentId : this.memberInfo.id;

      let params = {
        email: email,
        member: memberID || null,
        location: this.selectedLocationForWaitlist,
        search: {
            location: this.provider?.contact?.coordinates,
            address: this.$store.getters.getAddressSearched,
            ...this.$store.getters.getMarketSearchFilters,
        }
      }
    
      // /member/app/join/waitlist/member/ this was the previous endpoint. it needed member id
      api()
        .post(`/member/app/join/wait/list`, params)
        .then((res) => {
          // console.log("Adding Waitlist Success");
          // console.log(res.data);
          this.addedToWaitlist = true; // IF successful
        })
        .catch((err) => {
          console.log("Adding Waitlist Error");
          console.log(err);
          // console.log(err.response.data);
        });
    },
    saveEmailAndNotifyMe() {
      this.saveWaitlist(this.unregisteredEmail);
      this.notifyMe = false;
      
    },
    joinWaitlist(providerID) {
      this.selectedLocationForWaitlist = providerID;

      if(!this.loggedIn) { // If NOT logged in
        // console.log("get user email from input");
        this.notifyMe = true;
      }
      else {
        // console.log("get user email fomr memberInfo");
        if(this.memberInfo.email) {
          this.saveWaitlist(this.memberInfo.email);
        }
        else {
          this.noEmail = true;
        }
      }
    },
    goToMarketplace() {
      this.$router.push('/marketplace');
      // this.$router.push('/marketplace').then(() => {
      //   // Reload the page after the navigation is complete
      //   // This will make the sidebar appear
      //   window.location.reload();
      // });
    },
    resolveMarketplaceBackgroundColor(card) {
      // console.log(card);
      if(card){
        if (card?.entity == "location") {
          return "border-left: 15px solid #172E4D !important; border-radius: 8px 0 0 8px !important";
        } else {
          return "border-left: 15px solid #249999 !important; border-radius: 8px 0 0 8px !important";
        }
      }
    },
    returnClinicanName(inputString){
      if(inputString){
        const words = inputString.split(' ');
        const capitalizedWords = words.map(word => {
          if (word.length === 0) return word;
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
        const capitalizedString = capitalizedWords.join(' ');
        return capitalizedString;
      }
        
    },
    goToDetails(item, entity) {
      if (entity == "solution") {
        this.$router.push(`/marketplace/solutions/details/${item.id}`);
      } else if (entity == "location") {
        this.$store.state.documentTitle = item.name;
        this.$router.push({
          path: `/marketplace/provider/details/${item.id}`,
          query: { location: item.locationId },
        });
      } else if (entity == "vendor") {
        this.$router.push(`/marketplace/vendor/details/${item.id}`);
      } else if (entity == "provider") {
        this.$router.push(`/marketplace/clinician/details/${item.id}`);
      }
      this.showOverlay = false;
    },
    goToSection(tab) {
      for (let i = 0; i < this.profileTab.length; i++) {
        this.profileTab[i].status = 'close';
      }
      const indx = this.profileTab.findIndex(obj => obj.tabID === tab.tabID);
      if (indx !== -1) {
        this.profileTab[indx].status = "open";
      }
      // this.$refs[this.profileTab[indx].tabID].scrollIntoView({ behavior: 'smooth' }); //Ref doesn't seem to work
      document.getElementById(this.profileTab[indx].tabID).scrollIntoView({behavior: 'smooth', top: 0});
    },
    getDirections() {
      var fromLat = this.$store.state.userLocation.lat;
      var fromLang = this.$store.state.userLocation.lng;
      var toLat = this.userCenter.lat;
      var toLang = this.userCenter.lng;

      // console.log(fromLat, fromLang, toLat, toLang);
      window.open(`https://www.google.com/maps/dir/${fromLat},${fromLang}/${toLat},${toLang}`, '_blank');
    },
    openWebsite(website) {
      if(website.toLowerCase().includes('http')) {
        window.open(`${website}`, '_blank')
      }
      else {
        window.open(`https://${website}`, '_blank')
      }
    },
    distance_format(distance) {
      if(distance) {
        return distance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
    },
    returnCapitalizedName(inputString){
      // console.log(inputString);
      if(inputString) {
        const words = inputString.split(' ');
        const capitalizedWords = words.map(word => {
          if (word.length === 0) return word;
         return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
        const capitalizedString = capitalizedWords.join(' ');
        return capitalizedString;
      }
    },
    selectAnotherClinic() {
      this.$store.commit("setDependent", this.dependentId);
      this.$router.push("/marketplace");
    },
    checkCustomModal() {
      api()
      .get(`/member/app/provider/${this.provider.id}/postenrollment`) // dpcID is teh same as providerId
      .catch((err) => {
        console.log(err);
        this.selectingProvider = false;
        console.log("Something went wrong while checking for previder's custom link: ", err.response.data);
      })
      .then((res) => {
        console.log(res);
        if(res) {
          if(res.data.postMemberEnrollment !== null) {
            this.postMemberEnrollment = res.data.postMemberEnrollment;
            this.selectingProvider = false;
            
            // console.log("With Redirect link");

            if(this.$store.getters.getDependentId) {
              // For Dependent's Modal
              // this.dependentCustomLink = true;
              this.dependentAllSet = true;
            }
            else if(this.$store.getters.getDependentId == this.memberInfo.id) {
              // If current account is selecting a provider regarless if dependet account or subscriber account
              // this.ifCustomModal = true; // Subscriber's Modal
              this.subscriberAllSetModal = true; // // So user can be redirected to Membership Card page
            }
            else {
              // Selecting provider for self 
              // this.ifCustomModal = true; // Subscriber's Modal
              this.subscriberAllSetModal = true; // // So user can be redirected to Membership Card page
            }
          }
          else { // No Custom Link
            // console.log("else 1");
            // If custom modal isn't needed to be displayed
            this.selectingProvider = false;
            

            if(this.$store.getters.getDependentId) {
              this.dependentAllSet = true; // Dependent's Modal
            }
            else if(this.$store.getters.getDependentId == this.memberInfo.id) {
              // If current account is selecting a provider regarless if dependet account or subscriber account
              this.subscriberAllSetModal = true; // Subscriber's Modal
            }
            else {
              // Selecting provider for self 
              this.subscriberAllSetModal = true; // Subscriber's Modal
            }
          }

          //Dispatch getMemberInfo
          this.$store.dispatch('getMemberInfo');
        }
      });
    },
    redirect() {
      //Get redirect destination from localStorage
      let redirect = localStorage.getItem('redirect');

      //See where to redirect
      if(redirect == "/home") {
        this.$router.push('/home')
      }
      else if(redirect == "/account/details") {
        this.$router.push('/account/details')
      }
      else {
        // console.log("Redirect is empty: ", redirect);
      }
    },
    goToCustomRedirect(redirect) {
      window.open(redirect, '_blank');
      this.redirect();
    },
    generateChangeProviderTitle(status) {
        const firstLetter = this.memberInfo?.subscription?.status?.charAt(0).toLowerCase();

        const indefiniteArticle = ['a', 'e', 'i', 'o', 'u'].includes(firstLetter) ? 'an' : 'a';

        return `You${this.dependentInfo && this.dependentInfo.nextStep ? 'r dependent' : ''} have ${indefiniteArticle} ${status} membership with`
    },
    selectLocation() {
        this.selectedLocationToChange = this.provider;

        if (this.loggedIn) {
            if(this.dependentInfo && this.dependentInfo.nextStep) { // If dependent
                if (this.dependentInfo?.subscription?.status == 'active') {
                    this.cannotEnrollModal = true
                } else if (this.dependentInfo?.subscription?.status == 'pending') {
                    this.changeProviderModal = true;
                    this.selectedLocationToChange = {
                        ...this.selectedLocationToChange,
                        name: this.provider.companyName,
                        title: this.generateChangeProviderTitle(this.dependentInfo?.subscription?.status),
                        oldLocation: this.dependentInfo?.location?.name,
                        isDependent: true
                    };
                } else {
                    this.goToRegister()
                }
            } else { // Else if subscriber
                if (this.memberInfo?.subscription?.status == 'active') {
                    this.cannotEnrollModal = true;
                } else if (this.memberInfo?.subscription?.status == 'pending' && this.memberInfo?.nextStep !== "selectDPC") {
                    this.changeProviderModal = true
                    this.selectedLocationToChange = {
                        ...this.selectedLocationToChange,
                        name: this.provider.companyName,
                        title: this.generateChangeProviderTitle(this.memberInfo?.subscription?.status),
                        oldLocation: this.memberInfo?.location?.name
                    };
                } else {
                    this.goToRegister()
                }
            }
        } else {
            this.goToRegister()
        }
    },
    async updateProvider() {
        this.selectingProvider = true; 
        this.changeProviderModal = false;

        let data = {
            providerId: this.selectedLocationToChange.id,
            locationId: this.selectedLocationToChange.locationId,
        };

        if(!this.dependentInfo && !this.dependentInfo.nextStep) { // If this.$store.getters.getDependentId is the same as subscriber ID
            let params = "";
            if (this.selectedLocationToChange.locationId) {
                params = `?locationId=${this.selectedLocationToChange.locationId}`;
            }
            
            api()
                .get(`/member/app/market/provider/${this.selectedLocationToChange.id}` + params)
                .then((res) => {
                    console.log('res', res);
                    if (res) {
                        this.provider = res.data;
                        this.$store.commit("setSelectedProvider", this.provider);
                        const dependentId = this.$store.getters.getDependentId;

                        // Selecting provider for dependent
                        if (this.$store.getters.getDependentId) {
                            api()
                                .post(
                                `/member/app/family/member/${this.$store.getters.getDependentId}/set/provider`,
                                data
                                )
                                .catch((err) => {
                                    if (err) {
                                        console.log(
                                        "Dependent Enrollment Error : ",
                                        err.response.data
                                        );
                                        this.errorMessage = err.response.data;
                                        this.selectingProvider = false;
                                        this.dependnetErrorModal = true;

                                        // Empty Dependent ID from Store
                                        this.$store.commit("setDependent", "");
                                    }
                                })
                                .then(async (res) => {
                                    if (res) {
                                        // this.dependnetAllSetModal = true;

                                        // Empty Dependent ID from Store
                                        this.$store.commit("setDependent", "");

                                        //Dispatch getMemberInfo
                                        this.$store.dispatch("getDependentInfo", dependentId);
                                        await this.$store.dispatch("asyncGetMemberInfo");
                                        this.getDependentInfo(dependentId)

                                        this.selectingProvider = false;
                                        this.changedProviderModal = true;
                                    }
                                });
                        }
                    }
            });
        } else {
            await api()
            .post("/member/app/set/provider", data)
            .catch((err) => {
              if (err) {
                // console.log("Error", err.response.data);
                this.errorMessage = err.response.data;
                this.selectingProvider = false;
                this.dependnetErrorModal = true;
              }
            })
            .then(async (res) => {
              if (res) {
                //Dispatch getMemberInfo
                this.$store.dispatch("getMemberInfo");
                this.$store.dispatch('getEmployerPlans');

                this.selectingProvider = false;
                this.changedProviderModal = true;
              }
            });
            
        }
    },
    goToRegister() {
      // this.loggedIn = false; // K.N why do this?

      // Poppulate Selected Provider
      this.selectedProvider = this.provider;

      // Show loading modal
      this.selectingProvider = true; 

      // Mixpanel Integrations
      let tracking_user_id;
      if(this.memberInfo.id) { // If logged in
        tracking_user_id = this.memberInfo
      }
      else {
        tracking_user_id = null;
      }
      // Get time stamp with MongoDB timestamp format (2022-03-11T15:30:00.000Z)
      const selecting_provider_timestamp = new Date().toISOString();
      // Track Selected Provider
      Mixpanel.track("member_clinic_selected", {
        user_id: tracking_user_id, 
        timestamp: selecting_provider_timestamp,
        clinic_selected: this.provider,
      });

        if (!this.$store.getters.getDependentId) {
            // Set dependent id
            if (this.memberInfo.id && this.memberInfo.subscriberRelation === 'Self') {
                this.$store.commit("setDependent", this.memberInfo.id);
            } else if (this.memberInfo.id && this.memberInfo.associatedDependents[0]) {
                this.$store.commit("setDependent", this.memberInfo.associatedDependents[0]);
            }
        }

      if (this.loggedIn) { // If Logged In
       try {
         // alert();
        // Selecting a provider for the currently logged in account regardless if Self or Dependent
        if (this.memberInfo?.nextStep != 'complete') {
            // Sometimes, this.$store.getters.getDependentId is being used to select provider for subscriber
            // If subscriber is being resgistered by dependent as Self
            // So we must also consider the condition
            if(this.$store.getters.getDependentId && this.$store.getters.getDependentId == this.memberInfo.id) { // If this.$store.getters.getDependentId is the same as subscriber ID
              this.selectProviderForSubscriber();
            }
            else { // Else, it must be a dependent
              this.selectProviderForDependent();
            }
          }
          else if (this.$store.getters.getDependentId && this.$store.getters.getDependentId != this.memberInfo.id) {
            // For Dependents enrollment ONLY
            this.selectProviderForDependent();
          }
          else if(this.memberInfo?.nextStep == 'complete') {
            this.selectingProvider = false; 
            this.cannotEnrollModal = true;
          }
       } catch (error) {
            this.selectingProvider = false;
            console.log('goToRegister error', error);
       }
      } else {
        this.$store.commit("setSelectedProvider", this.provider);
        this.$router.push("/register");
      }
    },
    selectProviderForSubscriber() {
      // console.log("Select Provider for Dependents enrollment (using Dependents account)");
      // console.log("memberInfo: ", this.memberInfo);  
      // console.log("Dependent ID: ", this.memberInfo.id);
      // console.log("DPC ID: ", this.provider.id);
      // console.log("card", this.provider);

      let data = {
        providerId: this.provider.id,
        locationId: this.provider.locationId,
      };
      api()
      .post("/member/app/set/provider", data)
      .catch((err) => {
        if (err) {
          console.log('selectProviderForSubscriber() Error: ',err.response.data)
          this.errorMessage = err.response.data;
          this.selectingProvider = false;
          this.dependnetErrorModal = true;
        }
        })
        .then(async (res) => {
          if(res) {
            //Dispatch getMemberInfo
            await this.$store.dispatch('asyncGetMemberInfo');
            if (this.memberInfo?.nextStep == 'confirmPlan'){
              this.selectingProvider = false;
              this.$store.commit("setSelectedProvider", this.provider);
              this.$store.dispatch('getEmployerPlans');
              this.$router.push('/confirm-plan-information');   
            }     
            // If user's eligibility confirmation is complete,
            // then we show this modal
            else {
              this.selectingProvider = false;
              // Check for provider custom links
              this.checkCustomModal();
            }
          }
        });
    },
    selectProviderForDependent() {
      // console.log("Select Provider for Dependents enrollment (using Subscribers account)");
      // console.log("memberInfo: ", this.memberInfo);  
      // console.log("Dependent ID: ", this.$store.getters.getDependentId);
      // console.log("Subscriber ID: ", this.memberInfo.id);
      // console.log("DPC ID: ", this.provider.id);
      // console.log("card", this.provider);

      let data = {
        providerId: this.provider.id,
        locationId: this.provider.locationId,
      };

      api().post(`/member/app/family/member/${this.$store.getters.getDependentId}/set/provider`,  data)
        .catch((err) => {
          if (err) {
            console.log('selectProviderForDependent() Error: ', err.response.data)
            this.errorMessage = err.response.data;
            this.selectingProvider = false;
            this.dependnetErrorModal = true

            // Empty Dependent ID from Store
            this.$store.commit("setDependent", "");
          }
        })
        .then((res) => {
          if (res) {

            // Get Member Info
            api().get(`/member/app/family/member/${this.$store.getters.getDependentId}`)
            .then((res) => {
              this.selectedMember = res.data
              // console.log("this.selectedMember", this.selectedMember);
            })
            .catch((err) => {
              alert(err.response.data);
            });

            // Check for provider custom links
            this.checkCustomModal();
          }
        });
    },
    openNewMemberModal() {
      this.informativeModal = false;
      // this.dependentModal = true;
      // this.newMember.companyName = this.homeInfo.companyName;
      // this.newMember.planId = this.homeInfo.planID;
      // this.newMember.payerId = this.homeInfo.payerID;
      // this.newMember.groupId = this.homeInfo.groupID;
      //this.newMember.memberId = this.homeInfo.memberID;
      this.$router.push('/add-family-member')
    },
    closeSuccessModal() {
      this.$parent.getSolutions();
      this.$router.push("/marketplace");
    },
    async getMember() {
        await this.$store.dispatch("asyncGetMemberInfo");
      await api()
        .get(`/member/app/member/info`)
        .then((res) => {
          if (res) {
            this.member = res.data;
            // this.memberInfo = res.data;
            this.member.dob = res.data.dob ? res.data.dob.substring(10, 0) : "";

            // T.N. only for debuging
            // if(res.data.hasFamily){
            //   // this.clearNewMemberModal()
            //   // this.openNewMemberModal()
            //   // this.memberExists = true
            // }
          }
        this.getDependentInfo()
        })
        .catch((err) => {
          console.log("getMember() Error: ", err);
        });
    },
    getHomeInformation() {
      api()
        .get(`/member/app/home`)
        .then((res) => {
          if (res) {
            this.homeInfo = res.data;
          }
        })
        .catch((err) => {
          console.log("getHomeInformation() Error: ", err);
        });
    },
    getInformation() {
      api()
        .get(`/member/app/home`)
        .then((res) => {
          if (res) {
            this.homeInfo = res.data;
            if (this.provider.contracted == false) {
              //  this.cannotEnrollModal = true;
            } else {
              if (this.homeInfo.setup == "incomplete") {
                if (
                  res.data.locationId == null ||
                  res.data.providerId == null
                ) {
                  window.location = "/marketplace";
                } else {
                  if (res.data.planID == null) {
                    if (this.plans.length == 1) {
                      this.membersPlanModalStatus = false;
                      this.$emit("selectedPlan", this.plans[0]);
                      this.memberInfo.plan = this.plans[0].name
                        ? this.plans[0].name
                        : "";
                      this.memberInfo.planId = this.plans[0].planId;
                      this.$store.dispatch('getEmployerPlans');
                this.$router.push('/confirm-plan-information');
                    } else {
                      this.$store.dispatch('getEmployerPlans');
                this.$router.push('/confirm-plan-information');
                    }
                  } else {
                    if (this.plans.length == 1) {
                      this.membersPlanModalStatus = false;
                      this.$emit("selectedPlan", this.plans[0]);
                      this.memberInfo.plan = this.plans[0].name
                        ? this.plans[0].name
                        : "";
                      this.memberInfo.planId = this.plans[0].planId;
                    }
                    this.$router.push("/confirm-plan-information");
                  }
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log("getInformation() Error: ", err);
        });
    },
    getPlans() {
      api()
        .get(`/member/app/plans`)
        .then((res) => {
          if (res) {
            this.plans = res.data;
          }
        })
        .catch((err) => {
          if (err.response.data) {
            this.error = err.response.data;
          } else {
            this.error = "Something went wrong. Please try again later.";
          }
        });
    },

    finishedEnrollment() {
      this.dependentEnrolled = false;
      this.$store.commit("setDependent", "");
      this.$router.push("/marketplace");
    },
    addDependentAfterEnroll() {
      this.$store.commit("setDependent", "");
      this.dependentEnrolled = false;
      this.dependentModal = true;
    },
    gotIt() {
      this.$store.commit("setEnrollmentStatus", false);
      if (this.dependentId) {
        this.chooseProvider = false;
        this.$store.commit("setDependent", "");
        this.$router.push("/home");
      } else {
        if (this.hasFamily) {
          this.chooseProvider = false;
          this.$router.push('/add-family-member');
        } else {
          this.chooseProvider = false;
          this.$router.push("/home");
        }
      }
    },
    closeEnrollmentModal(){
      this.$store.commit("setEnrollmentStatus", false);
      this.chooseProvider = false;
    },
    changeTab(tab) {
      this.tab = tab;
    },
    getProvider() {
      let params = "";
      if (this.$route.query.location) {
        params = `?locationId=${this.$route.query.location}`;
      }
      if (this.dependentId) {
        api()
          .get(
            `/member/app/family/member/${this.dependentId}/market/provider/${this.$route.params.id}` +
              params
          )
          .then((res) => {
            if (res) {
              this.provider = res.data;
              if(res.data.details && res.data.details.description != null && res.data.details?.description.length > 300){
                this.descLength = true;
                
              }
              else{
                this.descLength = false;
              }
              if (this.provider.services) {
                this.hasPediatric = this.provider.services.some(
                  (item) => item.serviceType == "Pediatric"
                );
              }

              this.$store.state.documentTitle = this.provider.companyName;
              this.provider.logo = res.data.logo ? res.data.logo : "";
            }
            this.selectingProvider = false;
          });
      } else {
        api()
          .get(`/member/app/market/provider/${this.$route.params.id}` + params)
          .then((res) => {
            if (res) {
              this.provider = res.data;
              // console.log('res ', res);
              // First check if backend has sent the coordinates use that, else query google for the address
              if(res.data && res.data.details && res.data.contact && res.data.contact.coordinates){
                // TODO in BE: send coord as floats / or save them as floats (create ticket)
                let coord = res.data.contact.coordinates
                this.userCenter = { lat: parseFloat(coord.lat), lng: parseFloat(coord.lng) }

                // Set to state the location of the selected clinic
                this.$store.commit("setSelectedClinicLocation", this.userCenter);
              }
              else this.geocodeLocation(this.provider);
              if(res.data.details && res.data.details.description != null && res.data.details?.description.length > 300){
                this.descLength = true;
                
              }
              else{
                this.descLength = false;
              }
              if (this.provider.services) {
                this.hasPediatric = this.provider.services.some(
                  (item) => item.serviceType == "Pediatric"
                );
              }
              this.$store.state.documentTitle = this.provider.companyName;
              this.provider.logo = res.data.logo ? res.data.logo : "";
            }
            this.selectingProvider = false;
          });
      }
    },
    geocodeLocation(item) {
      // console.log('itm',item)
      // const address = item.contact.address1 + item.contactcity + item.contactstate + item.contactzipCode;
      const address = `${item.contact.address1} ${item.contact.city}, ${item.contact.state} ${item.contact.zipCode}`
        
        api()
            .post(`/member/app/market/locations/geocode`, { address })
            .then((res) => {
                if (res && res.data) {
                    const { lat, lng } = res.data.results[0].geometry.location;
                    item.latitude = lat;
                    item.longitude = lng;
                    const placeItem = { item, location: res.data.results[0].geometry.location };
                
                    this.userCenter = res.data.results[0].geometry.location;
                    // console.log('this.userCenter ',this.userCenter )
                }
            })
            .catch((error) => {
                console.error('Geocoding error:', error);
            });
  },
  setUpGoogleMaps(){
      this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("pac-input"),
      { types: ["geocode"] }
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
    },
    handlePlaceChanged() {
      const place = this.autocomplete.getPlace();
      if (place && place.formatted_address) {
        const selectedAddress = place.formatted_address;
        this.searchByZip();
      }
    },
    openCard(place){
      // console.log(place);
      if(place.location){
        this.infoWindowPosition = place.location;
      }else{
        this.infoWindowPosition = place;
      }

      this.infoWindowContent = place;
      if (place && place.item) {
        this.card = place.item;
      } else {
        this.card = null;
      }
      this.infoWindowOpened = true;
    },
    closeInfoWindow() {
      this.infoWindowOpened = false;
      this.selectedPlace = null; 
    },
    backToMarketplace() {
      this.$store.commit("setDependent", "");
      this.$router.push("/marketplace");
    },
    favorite() {
      let data = {
        entity: "location",
        location: this.$route.query.location,
        favorite: !this.provider.favorite,
      };
      api()
        .post("/member/app/market/favorite", data)
        .then((res) => {
          if (res) {
            this.provider.favorite = !this.provider.favorite;
          }
        });
    },
    returnCardImage(provider) {
      // console.log(provider);
      if (provider && provider.logo && provider.logo != "") {
        return provider.logo;
      } else {
        if (provider && provider.type == "Clinician") {
          if (provider && provider.gender == "M") {
            return "/svg/clinicianCardMale.svg";
          } else {
            return "/svg/clinicianCard.svg";
          }
        } else {
          return "/tpa/clinicsAndOrganizations.svg";
        }
      }
    },
    resolveMarketplaceBackgroundColor(card) {
      if (card?.entity == "location") {
        return "background-color:#194680";
      } else {
        return "background-color:#80B7FF";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.footer-btn{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff; /* Set your desired background color */
  border-top: 1px solid #ccc; /* Add a border if needed */
  padding: 10px; /* Adjust the padding as needed */
  text-align: center;
}
.select-text{
  color: white !important;
}
.description-text {
  margin-bottom: 32px;
}
.active {
  color: #333333 !important;
  background-color: transparent !important;
  border-bottom: 1px solid #333333;
}
.text-ellipsis {
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.provider-header {
  height: 220px;
  background-color: #194680;
}
.provider-logo {
  margin-top: -80px;
  height: 118px;
  width: 168px;
  background-color: white;
  border: 0.5px solid #cccccc;
  border-radius: 4px;
  left: 8px;
  top: 50px;
  background-size: contain;
  background-position: center;
}

.provider-item-content-name p {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.3px;
  margin-top: 0;
}
.provider-item-content-category {
  display: flex;
  align-items: flex-start;
}
.provider-item-content-category p {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.035px;
  display: flex;
}
.provider-item-content-category img {
  width: 16px;
  height: 16px;
}
.clinician-header {
  height: 80px;
  background-color: #80b7ff;
}
.marketplace-item-header {
  height: 80px;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.marketplace-item-logo {
  position: absolute;
  height: 56px;
  width: 80px;
  background-color: white;
  border: 0.5px solid #cccccc;
  border-radius: 4px;
  left: 8px;
  top: 30px;
  background-size: contain;
  background-position: center;
}
.marketplace-item-content-name p {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.3px;
  margin-top: 0;
}
.marketplace-item-content-category {
  display: flex;
  align-items: flex-start;
}
.marketplace-item-content-category p {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035px;
  display: flex;
}
.marketplace-item-content-category img {
  width: 16px;
  height: 16px;
}
.marketplace-item-actions {
  display: flex;
  justify-content: space-between;
}
.marketplace-item{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.marketplace-item-actions{
  margin-top: auto;
}
</style>
